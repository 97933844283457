import { axiosInstance as axios, nodePortalAxiosInstance } from "./axiosInstance";

const shiftEndpoints = {
  createShift: "/create_appointment",
  //createShift: "/create/shift",
  shifts: "/staffing_events",
  shift: "/cancel_appointment",
  modifyShift: "/action/shift",
  //rescheduleShift: "/appointments/rescheduleStaffing",
  rescheduleShift: "/reschedule",
  assignProvider: "/shift_action",
  removeProvider: "/shift_action",
  resendOffers: "/shift_action",
  sendOffers: "/shift_action",
  createSlot: "/shift_action",
  removeSlot: "/shift_action",
  markAsNowShow: "/shift_action",
  assignProviderInAdminTab: "/shift_action",
  removeProviderInAdminTab: "/shift_action",
  resendOffersInAdminTab: "/shift_action",
  sendOffersInAdminTab: "/shift_action",
  createSlotInAdminTab: "/shift_action",
  removeSlotInAdminTab: "/shift_action",
  markAsNowShowInAdminTab: "/shift_action",
  deleteTemplate: "/delete/template",
  shiftDetails: "/appointments/staffing",
  updateShiftTitle: "/update/template/title",
  getFilteredProviders: "/get_location_providers",
  createSlotAssignPro: "/shift_action",
};
const shift = {
  createShift(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.createShift, params);
  },
  editShift(params) {
    return axios.post(shiftEndpoints.modifyShift, params);
  },
  assignProvider(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.assignProvider, params);
  },
  removeProvider(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.removeProvider, params);
  },
  resendOffer(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.resendOffers, params);
  },
  sendOffers(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.sendOffers, params);
  },
  createSlot(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.createSlot, JSON.stringify(params), {
      headers: {
        'Content-Type': 'application/json'
      }
    });
  },
  removeSlot(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.removeSlot, params);
  },
  markAsNowShow(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.markAsNowShow, params);
  },

  // ********************************** overview tab
  assignProviderInAdminTab(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.assignProviderInAdminTab, params);
  },
  removeProviderInAdminTab(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.removeProviderInAdminTab, params);
  },
  resendOfferInAdminTab(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.resendOffersInAdminTab, params);
  },
  sendOffersInAdminTab(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.sendOffersInAdminTab, params);
  },
  createSlotInAdminTab(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.createSlotInAdminTab, params);
  },
  removeSlotInAdminTab(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.removeSlotInAdminTab, params);
  },
  markAsNowShowInAdminTab(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.markAsNowShowInAdminTab, params);
  },
  // **********************************************
  deleteShift({
    id,
    businessId,
    locationId,
    client_cancellation_reason_id,
    cancellation_reason_index,
    quoted_fee,
    fee_reason,
    custom_cancellation_reason
  }) {
    return nodePortalAxiosInstance.post(`${shiftEndpoints.shift}`, {
      "cart_id": id,
      "business_id": businessId,
      "location_id": locationId,
      "client_cancellation_reason_id": client_cancellation_reason_id,
      "cancellation_index": cancellation_reason_index,
      "quoted_fee": quoted_fee,
      fee_reason,
      custom_cancellation_reason
    });
  },
  rescheduleShift({
    id,
    businessId,
    locationId,
    session_date,
    session_time,
    session_end_time,
    rebook,
    quoted_fee,
    fee_reason,
    cancellation_index,
    custom_cancellation_reason,
    additional_details,
    client_cancellation_reason_id
  }) {
    return nodePortalAxiosInstance.post(`${shiftEndpoints.rescheduleShift}?cart_id=${id}`, {
      "business_id": businessId,
      "location_id": locationId,
      "session_date": session_date,
      "session_time": session_time,
      "session_end_time": session_end_time,
      "quoted_fee": quoted_fee,
      fee_reason,
      "rebook": rebook,
      cancellation_index,
      custom_cancellation_reason,
      additional_details,
      client_cancellation_reason_id
    });
  },
  getAllShifts({
    queryKey: [, { locationId, businessId, fromDate, toDate, day, year, b2b_type }],
  }) {
    return nodePortalAxiosInstance.post(shiftEndpoints.shifts, {
      appointment: {
        business_id: businessId,
        location_id: locationId,
        from_session_date: fromDate,
        to_session_date: toDate,
        day: day,
        year: year,
        b2b_type
      }
    });
  },
  deleteTemplate(id, location_id, businessId) {
    return axios.delete(`${shiftEndpoints.deleteTemplate}/${id}`, {
      data: { location_id, businessId },
    });
  },
  getShiftDetails({ queryKey: [, { businessId, locationId, id }] }) {
    return axios.get(shiftEndpoints.shiftDetails, {
      params: { businessId, locationId, id },
    });
  },
  updateShiftTitle({ id, title, locationId, businessId }) {
    return axios.put(`${shiftEndpoints.updateShiftTitle}/${id}`, {
      title,
      location_id: locationId,
      businessId,
    });
  },
  getFilteredPros(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.getFilteredProviders, params);
  },
  createSlotAndAssignProvider(params) {
    return nodePortalAxiosInstance.post(shiftEndpoints.createSlotAssignPro, params);
  }
};

export default shift;
