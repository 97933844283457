import React, { useState } from "react";
import FeatherIcon from "feather-icons-react";
import moment from "moment-timezone";
import Card from "antd/es/card";
import "antd/es/card/style";
import profitSvg from "../../../static/img/icon/ModalProfit.svg";
import styles from "./ViewAppointmentModal.module.scss";
import { FlexRow, StyledInput } from "../../atoms";
import { Cards } from "../../atoms/cards/Cards";
import { ReactComponent as WorkSvg } from "../../../static/img/icon/work_outline.svg";
import appointmentsAPI from "../../../utilities/api/appointment";
import openNotification from "../../../utilities/openNotification";
import { useQueryClient } from "react-query";
import { get, isEmpty, join } from "lodash";
import { Button } from "../../atoms/buttons/buttons";
import { usePermissions } from "../../../PermissionsProvider/PermissionsProvider";

function AppointmentDetails({ appointment, refetch, able_to_adjust }) {
  let {
    status,
    product_category,
    session_date,
    time,
    number_of_providers,
    cart_end_time,
    assigned_providers_count,
    location_name,
    b2b_type
  } = appointment;
  const queryClient = useQueryClient();
  const { permissions, businessPermissions } = usePermissions();

  let hasPermission =
    businessPermissions?.appointment?.includes("shift_adjustment") &&
    permissions?.appointment?.includes("shift_adjustment");
  // let hasPostShiftPermission =
  //   businessPermissions?.appointment?.includes("post_shift_adjustment") &&
  //   permissions?.appointment?.includes("post_shift_adjustment");

  const [note, setNote] = useState(get(appointment, "b2b_cart_details.provider_notes", ""));
  const [changesMade, setChangesMade] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleProviderNotes = () => {
    setIsLoading(true);
    appointmentsAPI
      .updateProviderNotes({ appointment_id: appointment.appointment_number, provider_notes: note })
      .then((res) => {
        refetch();
        queryClient.refetchQueries("appointments");
        queryClient.refetchQueries("shifts");
        queryClient.refetchQueries("allBusinessesAppointments");
        queryClient.refetchQueries("businessAppointments");
        openNotification("Success", res.data.message, "success");
        setIsLoading(false);
      })
      .catch(({ response }) => {
        setIsLoading(false);
        openNotification("Error", response.data.message, "error");
      });
  }

  let notes_edit_allowed = (b2b_type === "staffing" || b2b_type === "corporate")
    && status !== "cancelled" && ((status !== "completed" && hasPermission) || (status === "completed"
      //  && hasPostShiftPermission
      && able_to_adjust));
  return (
    <>
      <div className="view-modal-body">
        <div className={styles["scrollbox"]}>
          {status === "pending" &&
            appointment.needs_attention &&
            !appointment.partially_filled && (
              <FlexRow>
                <FeatherIcon icon="alert-octagon" size={42} color="#C11500" />
                <p className={styles["needsAttention-warning"]}>
                  This request exceeded the specified date and time and we are
                  unable to find a Pro for this appointment. Please notify your
                  client as soon as possible and either modify or cancel this
                  appointment.
                </p>
              </FlexRow>
            )}
          <div className="view-modal-body-card">
            {get(appointment, "cart_products.0.client_name", "")
              || ((get(appointment, "b2b_cart_details.client_email", "") || appointment?.b2b_cart_details?.client_phone_number) && (appointment.is_concierge || appointment.is_staffing || appointment.is_corporate))
              || ((appointment?.client_email || appointment?.client_phone_number) && !(appointment.is_concierge || appointment.is_staffing || appointment.is_corporate))
              || !isEmpty(appointment?.pro_equipments) ? <Card
                style={{
                  backgroundColor:
                    !appointment.partially_filled && appointment.needs_attention
                      ? "#FFEFED"
                      : "#F1F6FF",
                  color:
                    !appointment.partially_filled && appointment.needs_attention
                      ? "#C11500"
                      : "#272B41",
                  marginBottom: "22px",
                  border: "none",
                }}
                headless="true"
              >
              <p
                className="view-modal_section-title"
                style={{
                  color:
                    !appointment.partially_filled &&
                      status === "pending" &&
                      appointment.needs_attention
                      ? "#C11500"
                      : "#586B94",
                }}
              >
                Client
              </p>
              <FlexRow className="align-items-center">
                <FeatherIcon
                  icon="user"
                  size={16}
                  color={
                    !appointment.partially_filled &&
                      status === "pending" &&
                      appointment.needs_attention
                      ? "#C11500"
                      : "#586B94"
                  }
                  className="mr-10"
                />
                <p>{appointment.cart_products[0].client_name}</p>
              </FlexRow>
              <FlexRow className="align-items-center">
                {(((appointment.is_concierge || appointment.is_staffing || appointment.is_corporate) &&
                  appointment?.b2b_cart_details?.client_email) ||
                  (!appointment.is_concierge &&
                    !appointment.is_staffing &&
                    !appointment.is_corporate &&
                    appointment?.client_email)) && (
                    <>
                      <FeatherIcon
                        icon="mail"
                        size={16}
                        color={
                          !appointment.partially_filled &&
                            status === "pending" &&
                            appointment.needs_attention
                            ? "#C11500"
                            : "#586B94"
                        }
                        className="mr-10"
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        {appointment.is_concierge ||
                          (appointment.is_staffing && appointment.b2b_cart_details) ||
                          (appointment.is_corporate && appointment.b2b_cart_details)
                          ? appointment.b2b_cart_details.client_email
                            .split(",")
                            .map((email, index) => (
                              <div key={index}>{email.trim()}</div>
                            ))
                          : appointment.client_email
                            .split(",")
                            .map((emails, index) => (
                              <div key={index}>{emails.trim()}</div>
                            ))}
                      </div>
                    </>
                  )}
              </FlexRow>
              <FlexRow className="align-items-center">
                {(((appointment.is_concierge || appointment.is_staffing || appointment.is_corporate) &&
                  appointment?.b2b_cart_details?.client_phone_number) ||
                  (!appointment.is_concierge &&
                    !appointment.is_staffing &&
                    !appointment.is_corporate &&
                    appointment?.client_phone_number)) && (
                    <FlexRow>
                      <FeatherIcon
                        icon="phone"
                        size={16}
                        color={
                          !appointment.partially_filled &&
                            status === "pending" &&
                            appointment.needs_attention
                            ? "#C11500"
                            : "#586B94"
                        }
                        className="mr-10"
                      />
                      {(appointment.is_concierge || appointment.is_staffing || appointment.is_corporate) &&
                        appointment.b2b_cart_details
                        ? appointment.b2b_cart_details.client_phone_number
                        : appointment.client_phone_number}
                    </FlexRow>
                  )}
              </FlexRow>
              <FlexRow className="align-items-center">
                <WorkSvg
                  style={{
                    color: !appointment.partially_filled &&
                      status === "pending" &&
                      appointment.needs_attention
                      ? "#C11500"
                      : "#586B94",
                    width: "16px",
                  }}
                  className="mr-10" />
                <p>{join(appointment.pro_equipments, ", ")}</p>
              </FlexRow>
            </Card> : null}
            <Card
              style={{
                backgroundColor:
                  !appointment.partially_filled &&
                    status === "pending" &&
                    appointment.needs_attention
                    ? "#FFEFED"
                    : "#F1F6FF",
                color:
                  !appointment.partially_filled &&
                    status === "pending" &&
                    appointment.needs_attention
                    ? "#C11500"
                    : "#272B41",
                marginBottom: "22px",
                border: "none",
              }}
              headless="true"
            >
              <p className="view-modal_section-title"
                style={{
                  color:
                    !appointment.partially_filled &&
                      status === "pending" &&
                      appointment.needs_attention
                      ? "#C11500"
                      : "#586B94",
                }}>
                Details
              </p>
              <FlexRow>
                <FeatherIcon
                  icon="calendar"
                  size={16}
                  color={
                    !appointment.partially_filled &&
                      status === "pending" &&
                      appointment.needs_attention
                      ? "#C11500"
                      : "#9299B8"
                  }
                  className="mr-10"
                />
                <p>{moment(session_date).format("dddd, MMM DD")}</p>
              </FlexRow>
              <FlexRow>
                <FeatherIcon
                  icon="clock"
                  size={16}
                  color={
                    !appointment.partially_filled &&
                      status === "pending" &&
                      appointment.needs_attention
                      ? "#C11500"
                      : "#9299B8"
                  }
                  className="mr-10"
                />
                <p>{`${time?.display} - ${cart_end_time?.display}`}</p>
              </FlexRow>
              {(appointment?.b2b_type !== "staffing" && appointment?.b2b_type !== "corporate") &&
                appointment?.address?.hotel_room_number !== "" &&
                appointment?.address?.hotel_room_number !== null && (
                  <FlexRow>
                    <FeatherIcon
                      icon="hash"
                      size={16}
                      color={
                        !appointment.partially_filled &&
                          status === "pending" &&
                          appointment.needs_attention
                          ? "#C11500"
                          : "#9299B8"
                      }
                      className="mr-10"
                    />
                    <p>Room {appointment?.address?.hotel_room_number}</p>
                  </FlexRow>
                )}
              <FlexRow>
                <FeatherIcon
                  icon="map-pin"
                  size={16}
                  color={
                    !appointment.partially_filled &&
                      status === "pending" &&
                      appointment.needs_attention
                      ? "#C11500"
                      : "#9299B8"
                  }
                  className="mr-10"
                />
                <p>{location_name}</p>
              </FlexRow>

              <FlexRow>
                <FeatherIcon
                  icon="list"
                  size={16}
                  color={
                    !appointment.partially_filled &&
                      status === "pending" &&
                      appointment.needs_attention
                      ? "#C11500"
                      : "#9299B8"
                  }
                  className="mr-10"
                />
                <p>{product_category}</p>
              </FlexRow>
              <FlexRow>
                <FeatherIcon
                  icon="users"
                  size={16}
                  color={
                    !appointment.partially_filled &&
                      status === "pending" &&
                      appointment.needs_attention
                      ? "#C11500"
                      : "#9299B8"
                  }
                  className="mr-10"
                />
                <div>
                  {assigned_providers_count} of {number_of_providers} Providers
                  Confirmed
                </div>
              </FlexRow>
              <FlexRow>
                <FeatherIcon
                  icon="user-check"
                  size={16}
                  color={
                    !appointment.partially_filled &&
                      status === "pending" &&
                      appointment.needs_attention
                      ? "#C11500"
                      : "#9299B8"
                  }
                  className="mr-10"
                />
                <p>
                  {status === "cancelled" ? "Cancelled by" : "Booked by"}{" "}
                  {(appointment.is_concierge || appointment.is_staffing || appointment.is_corporate) &&
                    appointment?.b2b_cart_details ? (
                    <span className={styles["booked-by"]}>
                      {status === "cancelled"
                        ? appointment?.b2b_cart_details?.cancelled_by
                        : appointment?.b2b_cart_details?.admin_name}
                    </span>
                  ) : (
                    <span className={styles["booked-by"]}>
                      {appointment.booked_by}
                    </span>
                  )}{" "}
                  on {appointment.created_at}
                </p>
              </FlexRow>
            </Card>
            <Card
              style={{
                backgroundColor:
                  !appointment.partially_filled &&
                    status === "pending" &&
                    appointment.needs_attention
                    ? "#FFEFED"
                    : "#F1F6FF",
                color:
                  !appointment.partially_filled &&
                    status === "pending" &&
                    appointment.needs_attention
                    ? "#C11500"
                    : "#272B41",
                marginBottom: "22px",
                border: "none",
              }}
              headless="true"
            >
              <p className="view-modal_section-title"
                style={{
                  color:
                    !appointment.partially_filled &&
                      status === "pending" &&
                      appointment.needs_attention
                      ? "#C11500"
                      : "#586B94",
                }}>Note to Provider</p>
              <StyledInput showCount
                className="textarea-with-count"
                id="update-apt_note"
                type="textarea"
                value={note}
                defaultValue={note}
                onChange={(e) => {
                  setNote(e.target.value)
                  if (note !== get(appointment, "b2b_cart_details.provider_notes", "")) {
                    setChangesMade(true)
                  }
                }}
                maxLength={250}
                placeholder="Enter shift details here..."
                style={{
                  marginBottom: "26px",
                  borderRadius: "3px",
                  border: "#E2E2E2"
                }}
                disabled={!notes_edit_allowed || isLoading}
              />
              {hasPermission || (status === "completed"
                //  && hasPostShiftPermission 
                && able_to_adjust) ? <div style={{ float: "right" }}>
                <Button
                  className={styles["save-btn"]}
                  onClick={() => {
                    if (notes_edit_allowed && !isLoading && changesMade) {
                      handleProviderNotes()
                    }
                  }}
                  type="light"
                  disabled={!notes_edit_allowed || isLoading || !changesMade}
                >
                  Save
                </Button>
              </div> : null}
            </Card>
            <Cards headless>
              <p className="view-modal_section-title">Order summary</p>
              <div className="view-modal_section-body">
                <FeatherIcon
                  icon="shopping-cart"
                  size={16}
                  color="#586B94"
                  className="mr-10"
                />
                <div style={{ width: "100%" }}>
                  <div className="view-modal_text-wrapper">
                    {appointment.billing.top_billing_line_items.map(
                      (item, index) => (
                        <div key={index} className="d-flex">
                          <div>{item.title}</div>
                          <p>{item.amount}</p>
                        </div>
                      )
                    )}
                  </div>
                  <div className="view-modal_text-wrapper">
                    {appointment.billing.billing_line_items_bottom.map(
                      (item, index) => (
                        <div key={index} className="d-flex">
                          <div>{item.title}</div>
                          <p>{item.amount}</p>
                        </div>
                      )
                    )}
                  </div>
                  <div className="view-modal_text-wrapper">
                    <div className="d-flex">
                      <div>Client total charge</div>
                      <p>{appointment.billing.price_with_tip}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex">
                <FlexRow>
                  <img src={profitSvg} alt="" className="mr-10" />
                  Your profit
                </FlexRow>
                <div>${parseFloat(appointment.billing.mark_up).toFixed(2)}</div>
              </div>
            </Cards>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppointmentDetails;