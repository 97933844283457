export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const decimalRegex = /^\d{1,4}(?:\.\d{1,2})?$/;

export const ABOUT = "About";
export const TREATMENTS = "Treatments";
export const ARRIVAL_INSTRUCTIONS = "Arrival Instructions";
export const CUSTOM_PRICING = "Custom Pricing";
export const PROVIDER_PAYOUT = "Provider Payout";
export const PAYMENTS_AND_BILLING = "Payments & Billing";
export const OPERATING_HOURS = "Operating Hours";
export const DISCOUNTS = "Discounts";
export const BILLING = "Billing";
export const PERMISSIONS = "Permissions";
export const PROVIDER_SOURCING = "Provider Preferences";

// WORKING HOURS RELATED CONSTANTS

export const DEFAULT_START_HOUR = 7;
export const DEFAULT_END_HOUR = 22;
export const FIXED_DISABLED_HOURS = [0, 1, 2, 3, 4, 5, 6, 23, 24];

export const ADDED_MARGIN_H = 2;
export const CALENDAR_HOURS = [
    "7 AM",
    "8 AM",
    "9 AM",
    "10 AM",
    "11 AM",
    "12 PM",
    "1 PM",
    "2 PM",
    "3 PM",
    "4 PM",
    "5 PM",
    "6 PM",
    "7 PM",
    "8 PM",
    "9 PM",
    "10 PM",
    "11 PM",
    "12 AM",
];
