import { axiosInstance as axios, nodePortalAxiosInstance } from "./axiosInstance";

const billingEndpoints = {
  accounting: "/accounting",
  details: "/accounting/details",
  accountingsPaginated: "/accounting/pagination",
  fees: "/cancellation_policies"
};

const billing = {
  getBillings({ queryKey: [, { location_id, business_id }] }) {
    return axios.get(`${billingEndpoints.accounting}`, {
      params: { business_id, location_id },
    });
  },
  getBillingsPaginated({
    queryKey: [, { page, size, location_id, business_id }],
  }) {
    return axios.get(`${billingEndpoints.accountingsPaginated}`, {
      params: { page, size, business_id, location_id },
    });
  },
  getBillingDetails(location_id, business_id, month) {
    return axios.get(`${billingEndpoints.details}`, {
      params: {
        business_id: business_id,
        location_id: location_id,
        month: month,
      },
    });
  },
  getFees({ location_id, business_id }) {
    return nodePortalAxiosInstance.get(`${billingEndpoints.fees}`, {
      params: { business_id, location_id },
    });
  },
  postFees({ cancellation_policies }) {
    return nodePortalAxiosInstance.post(`${billingEndpoints.fees}`, {
      cancellation_policies
    });
    // Payload example:
    // {
    //   "cancellation_policies": [
    //     {
    //       "b2b_type": "staffing",        //<-- required
    //       "time_before_booking": 120,
    //       "client_charge": null,
    //       "client_charge_cents": 10000,
    //       "pro_payout": null,
    //       "pro_payout_cents": 10000,
    //       "cancellation_type_id": 1,
    //       "location_id": 1,
    //       "business_id": 1
    //     },
    //   ]
    // }
  }
};

export default billing;
