import get from "lodash/get";
import { useQuery } from "react-query";
import { usePermissions } from "../../PermissionsProvider/PermissionsProvider";
import useTryAutoLogin from "./useTryAutoLogin";
import billing from "../api/billing";

const useGetFees = ({ location_id, business_id, isDefault, shouldFetch }) => {
    const id = location_id ? location_id : business_id;
    const { isLogin } = useTryAutoLogin();
    const { permissions } = usePermissions();
    const billingPermission = location_id ? permissions?.location?.includes("billing") : permissions?.business?.includes("billing");

    const { data, isError, isLoading } = useQuery(
        ["fees", id],
        () => billing.getFees(isDefault ? {} : { location_id, business_id }),
        {
            enabled:
                !!isLogin &&
                !!billingPermission &&
                shouldFetch,
            cacheTime: 0,
            staleTime: 0,
        }
    );
    const cancellation_policies = get(data, "data.cancellation_policies");
    return {
        cancellation_policies,
        isError,
        isLoading
    };
};

export default useGetFees;
