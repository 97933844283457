/* eslint-disable import/no-anonymous-default-export */
import { Skeleton } from "antd";
import "antd/es/skeleton/style";
import "antd/es/card/style";
import styles from "./CancellationFees.module.scss";
import React, { useEffect, useState } from 'react';
import "antd/es/input/style";
import "antd/es/input-number/style";
import { Button, InputNumber, Table } from 'antd';
import { concat, filter, find, flatten, get, indexOf, isEmpty, isNull, keys, last, map, replace, values } from "lodash";
import { SwitchToggle } from "../../../molecules/SwitchToggle";
import useGetFees from "../../../../utilities/hooks/useGetFees";


const CancellationFees = (props) => {
    const { categories, cancellation_policies, location_id, business_id } = props;
    const [isPercentageStaffing, setIsPercentageStaffing] = useState(false);
    const [isPercentageCorporate, setIsPercentageCorporate] = useState(false);
    const [staffingCP, setStaffingCP] = useState(null);
    const [corporateCP, setCorporateCP] = useState(null);
    const [spaDataSrc, setSpaDataSrc] = useState([]);
    const [cwDataSrc, setCWDataSrc] = useState([]);
    const [needToRefresh, setNeedToRefresh] = useState(false);
    const [policiesKeysStaffing, setPoliciesKeysStaffing] = useState([]);
    const [policiesKeysCorporate, setPoliciesKeysCorporate] = useState([]);
    useEffect(() => {
        if (!isEmpty(cancellation_policies) && isEmpty(staffingCP) && isEmpty(corporateCP)) {
            const sp = find(cancellation_policies, (el) => el.b2b_type === "staffing");
            const cp = find(cancellation_policies, (el) => el.b2b_type === "corporate");
            // policies
            setStaffingCP(get(sp, "policies", []));
            setCorporateCP(get(cp, "policies", []));
            // percentage or flat rate
            setIsPercentageStaffing(isNull(get(values(get(sp, "policies")), "0.0.client_charge_cents", null)));
            setIsPercentageCorporate(isNull(get(values(get(cp, "policies")), "0.0.client_charge_cents", null)));
            // keys
            setPoliciesKeysStaffing(keys(sp?.policies));
            setPoliciesKeysCorporate(keys(cp?.policies));
            setNeedToRefresh(true);
        }
    }, [cancellation_policies]);
    useEffect(() => {
        if (needToRefresh) {
            getRelevantDataSrc();
            getRelevantDataSrc(true);
            setNeedToRefresh(false);
            updateParentFees();
        }
    }, [needToRefresh]);

    const updateParentFees = () => {
        let tempStaffingCP = map(flatten(values(staffingCP)), (el) => (
            {
                ...el,
                ...business_id ? { business_id } : { location_id },
                "b2b_type": "staffing"
            }
        ));
        let tempCorporateCP = map(flatten(values(corporateCP)), (el) => (
            {
                ...el,
                ...business_id ? { business_id } : { location_id },
                "b2b_type": "corporate"
            }
        ));

        props.setFees(concat(tempStaffingCP, tempCorporateCP));
    }

    const columns = [
        {
            title: 'Action',
            dataIndex: 'feeAction',
            key: 'feeAction',
            onCell: (record) => {
                return {
                    rowSpan: record.elementsCount,
                    className: record.isLastGroup ? `${styles['br-btm-0']}` : '',
                }
            },
            className: `${styles['white-row']}`,
            width: "15%"
        },
        {
            title: 'Timeframe',
            dataIndex: 'timeframe',
            key: 'timeframe',
        },
        {
            title: 'Client Fee',
            dataIndex: 'clientFee',
            key: 'clientFee',
        },
        {
            title: 'Provider Payment',
            dataIndex: 'providerPayment',
            key: 'providerPayment',
        },
        {
            dataIndex: 'feeActions',
            key: 'feeActions',
            width: "10%"
        },
    ];
    const handleChange = (key, k, value, fieldName, isCorporate = false) => {
        let mainArr = isCorporate ? corporateCP : staffingCP;
        let subArr = mainArr[key];
        let isPerc = isCorporate ? isPercentageCorporate : isPercentageStaffing;
        let tempArray = filter(subArr, (el) => (!el.delete)),
            deletedArr = filter(subArr, (el) => (el.delete));
        let fieldName1 = fieldName === "client_charge" ? "client_charge_cents" : "pro_payout_cents";
        if (fieldName === "time_before_booking") {
            tempArray[k][fieldName] = value;
        } else {
            if (isPerc) {
                tempArray[k][fieldName] = value;
                tempArray[k][fieldName1] = null;
            } else {
                tempArray[k][fieldName] = null;
                tempArray[k][fieldName1] = Number(value) * 100;
            }
        }
        let newCD = concat(tempArray, deletedArr);
        mainArr[key] = newCD;
        if (isCorporate) {
            setCorporateCP(mainArr);
        } else {
            setStaffingCP(mainArr);
        }
        setNeedToRefresh(true);
    }
    const deleteRow = (key, k, isCorporate = false) => {
        let mainArr = isCorporate ? corporateCP : staffingCP;
        let subArr = mainArr[key];
        let tempArray = filter(subArr, (el) => (!el.delete)),
            deletedArr = filter(subArr, (el) => (el.delete));
        tempArray[k].delete = true;
        // subArr.splice(k, 1);
        mainArr[key] = concat(tempArray, deletedArr);
        if (isCorporate) {
            setCorporateCP(mainArr);
        } else {
            setStaffingCP(mainArr);
        }
        setNeedToRefresh(true);
    }
    const addRow = (key, index, cancellation_type_id, isCorporate = false) => {
        let mainArr = isCorporate ? corporateCP : staffingCP;
        let isPerc = isCorporate ? isPercentageCorporate : isPercentageStaffing;
        let subArr = mainArr[key];
        let tempArray = filter(subArr, (el) => (!el.delete)),
            deletedArr = filter(subArr, (el) => (el.delete));
        tempArray.splice(index + 1, 0, {
            client_charge: isPerc ? 1 : null,
            client_charge_cents: isPerc ? null : 0.01,
            pro_payout: isPerc ? 1 : null,
            pro_payout_cents: isPerc ? null : 0.01,
            time_before_booking: 1,
            cancellation_type_id
        })
        let newCD = concat(tempArray, deletedArr);
        mainArr[key] = newCD;
        if (isCorporate) {
            setCorporateCP(mainArr);
        } else {
            setStaffingCP(mainArr);
        }
        setNeedToRefresh(true);
    }
    const switchPercFlat = (isPerc, isCorporate = false) => {
        let tempCP;
        if (isCorporate) {
            tempCP = corporateCP;
        } else {
            tempCP = staffingCP;
        }
        map(tempCP, (el, it) => {
            map(el, (val, ind) => {
                const initialVal_C = val.client_charge,
                    initialVal_P = val.pro_payout;
                const initial_centsC = val.client_charge_cents,
                    initial_centsP = val.pro_payout_cents;
                tempCP[it][ind] = {
                    ...val,
                    client_charge: isPerc ? Number(initial_centsC / 100) : null,
                    pro_payout: isPerc ? Number(initial_centsP / 100) : null,

                    client_charge_cents: isPerc ? null : Number(initialVal_C * 100),
                    pro_payout_cents: isPerc ? null : Number(initialVal_P * 100)
                };
            })
        })
        if (isCorporate) {
            setIsPercentageCorporate(isPerc);
            setCorporateCP(tempCP);
        } else {
            setIsPercentageStaffing(isPerc);
            setStaffingCP(tempCP);
        }
        setNeedToRefresh(true);
    }

    const getRelevantDataSrc = (isCorporate = false) => {
        let isPerc = isCorporate ? isPercentageCorporate : isPercentageStaffing;
        let cpList = isCorporate ? corporateCP : staffingCP;
        let pKeys = keys(cpList);
        const dataSource = [];
        map(cpList, (mainValue, ky) => {
            let indexOfParent = indexOf(pKeys, ky);
            let value = filter(mainValue, (el) => (!el.delete));
            map(value, (val, k) => {
                let rlvtKy = `${replace(ky, new RegExp(' ', "g"), '-')}-${k}-${value?.length}`;
                dataSource.push({
                    key: rlvtKy,
                    index: k + indexOfParent,
                    feeAction: ky,
                    timeframe: <span>{'< '}<InputNumber min={1} pattern="[0-9\.]+" className="input-style-light w-50" defaultValue={val.time_before_booking}
                        onChange={(v) => handleChange(ky, k, v, "time_before_booking", isCorporate)} /> hours before start time</span>,
                    clientFee: <span>{isPerc ? '' : '$ '}
                        <InputNumber min={0.01} pattern="[0-9\.]+" className="input-style-light w-50" defaultValue={isPerc ? val.client_charge : Number(val.client_charge_cents) / 100}
                            onChange={(v) => handleChange(ky, k, v, "client_charge", isCorporate)} />
                        {isPerc ? ' % of shift total' : ''}</span>,
                    providerPayment: <span>{isPerc ? '' : '$ '}
                        <InputNumber min={0.01} pattern="[0-9\.]+" className="input-style-light w-50" defaultValue={isPerc ? val.pro_payout : Number(val.pro_payout_cents) / 100}
                            onChange={(v) => handleChange(ky, k, v, "pro_payout", isCorporate)} />
                        {isPerc ? ' % ' : ''}of Provider payout</span>,
                    subIndex: k,
                    elementsCount: k === 0 ? value?.length : 0,
                    isLastGroup: ky === last(isCorporate ? policiesKeysCorporate : policiesKeysStaffing),
                    feeActions: (
                        <div className="flex-around">
                            {value.length > 1 ? (
                                <Button
                                    // id={`remove-${rlvtKy}`}
                                    shape="circle"
                                    size="small"
                                    style={{
                                        borderColor: "#586B94",
                                        color: "#586B94",
                                        fontWeight: "500",
                                        fontSize: "20px",
                                        borderWidth: "2px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    onClick={() => deleteRow(ky, k, isCorporate)}
                                >
                                    -
                                </Button>
                            ) : null}
                            <Button
                                // id={`add-${rlvtKy}`}
                                shape="circle"
                                size="small"
                                style={{
                                    borderColor: "#586B94",
                                    color: "#586B94",
                                    fontWeight: "500",
                                    fontSize: "20px",
                                    borderWidth: "2px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                                onClick={() => addRow(ky, k, val.cancellation_type_id, isCorporate)}
                            >
                                +
                            </Button>
                        </div>)
                })
            })
        });
        if (isCorporate) {
            setCWDataSrc(dataSource);
        } else {
            setSpaDataSrc(dataSource);
        }
    }
    const feesTable = (isCorporate = false) => {
        return (<Table
            key={isCorporate ? "cw" : "spa"}
            headerSplitColor="#fff"
            className={`table-responsive with-rowspans`}
            dataSource={isCorporate ? cwDataSrc : spaDataSrc}
            columns={columns}
            size="small"
            pagination={false}
            scroll={{ y: 500 }}
            rowClassName={(_, index) => {
                return index % 2 === 0 ? `${styles['gray-row']} ${styles['noBorderLastRow']}` : `${styles['white-row']}  ${styles['noBorderLastRow']}`;
            }}
        />)
    }

    if (isEmpty(staffingCP) && isEmpty(corporateCP)) {
        return null;
    }
    return (<div>
        <div className="ptb-16">
            <div className="title-16 flex-spaced" style={{ justifyContent: "start" }} >Late Cancellation & Reschedule Fees</div>
        </div>
        {categories.includes("Spa") && !isEmpty(staffingCP) ?
            <div className="mb-40">
                <div className="flex-spaced align-items-center">
                    <div className="ptb-16 mr-10">
                        <div className="title-14">Spa Staffing</div>
                        <div className="subtitle-14">Spa staffing partners can cancel a shift at any time. Fees may be charged if the shift is shortened by 50% or more than the scheduled slot.</div>
                    </div>
                    <SwitchToggle
                        value={isPercentageStaffing}
                        copy={<span style={{ fontWeight: 500, color: isPercentageStaffing ? "#545454" : "#586B94" }}>Flat rate </span>}
                        onChange={(val) => switchPercFlat(val)}
                        defaultChecked={isPercentageStaffing}
                        secondCopy={<span style={{ fontWeight: 500, color: isPercentageStaffing ? "#586B94" : "#545454" }}> Percentage</span>}
                        size={"small"}
                        buttonStyle={"mr-8"}
                    />
                </div>
                <div style={{ border: "1px solid #e2e2e2", borderRadius: "16px", padding: "16px" }}>
                    {feesTable()}
                </div>
            </div> : null
        }
        {categories.includes("Corporate") && !isEmpty(corporateCP) ?
            <>
                <div className="flex-spaced align-items-center">
                    <div className="ptb-16 mr-10">
                        <div className="title-14">Corporate Wellness</div>
                        <div className="subtitle-14">Corporate Wellness customers can reschedule or cancel at any time, but it is recommended that Clients reschedule instead of cancel. Cancellation and reschedule fees may apply.</div>
                    </div>
                    <SwitchToggle
                        value={isPercentageCorporate}
                        copy={<span style={{ fontWeight: 500, color: isPercentageCorporate ? "#545454" : "#586B94" }}>Flat rate </span>}
                        onChange={(val) => switchPercFlat(val, true)}
                        defaultChecked={isPercentageCorporate}
                        secondCopy={<span style={{ fontWeight: 500, color: isPercentageCorporate ? "#586B94" : "#545454" }}> Percentage</span>}
                        size={"small"}
                        buttonStyle={"mr-8"}
                    />
                </div>
                <div style={{ border: "1px solid #e2e2e2", borderRadius: "16px", padding: "16px" }}>
                    {feesTable(true)}
                </div>
            </> : null
        }
    </div >)
}

export default (props) => {
    const {
        business_id,
        location_id
    } = props;
    let { cancellation_policies, isLoading } = useGetFees({ location_id, business_id, shouldFetch: true });

    const newQueryResponse = useGetFees({ location_id, business_id, isDefault: true, shouldFetch: isEmpty(cancellation_policies) && !isLoading }); // get default fees
    cancellation_policies = isEmpty(cancellation_policies) ? newQueryResponse.cancellation_policies : cancellation_policies;
    isLoading = isLoading || newQueryResponse.isLoading;

    if (isLoading) {
        return <Skeleton active />;
    }
    return <CancellationFees cancellation_policies={cancellation_policies} {...props} />;
}