import get from "lodash/get";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import moment from "moment-timezone";
import { round } from "lodash";
import { ADDED_MARGIN_H } from "./constants";

export const getBusinessName = (userData, businessId) => {
  const businessObj = find(
    get(userData, "businesses", []),
    (el) => el.id === businessId
  );
  return get(businessObj, "name", "");
};

export const getbusinessId = (businessList, CurrentbusinessId) => {
  let businessId;
  if (!isEmpty(businessList)) {
    businessId = CurrentbusinessId ?? get(businessList, "0.id", null);
    if (
      businessId &&
      !isEmpty(find(businessList, (el) => el.id === Number(businessId))) &&
      !businessList
        ?.map((business) => business.id)
        .includes(Number(localStorage.getItem("businessId")))
    ) {
      businessId = Number(businessId);
    } else {
      businessId = Number(localStorage.getItem("businessId"));
    }
  }

  return businessId;
};

export const getDisabledDates = (day, dateFormat, enabledDays, timeFormat) => {
  const availbleTimeRange = enabledDays[day.format("dddd")];
  if (!availbleTimeRange) return;
  const lastAppointmentEnabledHour = moment(
    availbleTimeRange?.end,
    timeFormat
  ).hour();

  const isCurrentDateAvailable =
    moment(day.format(dateFormat)).isSame(moment().format(dateFormat)) &&
    moment().hour() + ADDED_MARGIN_H > lastAppointmentEnabledHour;

  const isDateAvailabe =
    isCurrentDateAvailable ||
    moment().add(-1, "days") >= day ||
    !Object.keys(enabledDays).includes(String(day.format("dddd")));
  return isDateAvailabe;
  // todo revisit
};
export const getMinutes = (currenttimeMinutes) => {
  return (round(currenttimeMinutes / 5) * 5) % 60;
};

export const onTimeSelect = (
  time,
  form,
  setTime,
  selectedDay,
  enabledDays,
  dateFormat,
  timeFormat,
) => {
  const availbleTimeRange = enabledDays[selectedDay.day];
  if (!availbleTimeRange) return;

  const firstAppointmentAvailableHour = moment().hour() + ADDED_MARGIN_H;
  const firstAppointmentAvailableMinutes = moment().minute();

  const firstAppointmentEnabledHour = moment(
    availbleTimeRange.start,
    timeFormat
  ).hour();
  const firstAppointmentEnabledMinutes = moment(
    availbleTimeRange.start,
    timeFormat
  ).minute();
  const lastAppointmentEnabledHour = moment(
    availbleTimeRange.end,
    timeFormat
  ).hour();
  const lastAppointmentEnabledMinutes = moment(
    availbleTimeRange.end,
    timeFormat
  ).minute();

  if (moment().format(dateFormat) === selectedDay.date.format(dateFormat)) {
    if (time.hour() <= firstAppointmentEnabledHour) {
      if (time.hour() === firstAppointmentEnabledHour) {
        let selectedTime = time.set({
          hours: firstAppointmentEnabledHour,
        });
        setTime(selectedTime.format("HH:mm:ss"));

        form.setFieldsValue({
          time: selectedTime,
        });

        return;
      }
      if (firstAppointmentAvailableHour < firstAppointmentEnabledHour) {
        let selectedTime = time.set({
          hours: firstAppointmentEnabledHour,
          minutes: firstAppointmentEnabledMinutes,
        });
        setTime(selectedTime.format("HH:mm:ss"));

        form.setFieldsValue({
          time: selectedTime,
        });
        return;
      }
      if (firstAppointmentAvailableHour > firstAppointmentEnabledHour) {
        let selectedTime = time.set({
          hours:
            moment().minutes() > 57
              ? firstAppointmentAvailableHour + 1
              : firstAppointmentAvailableHour,
          minutes:
            moment().minutes() > 57
              ? 0
              : getMinutes(firstAppointmentAvailableMinutes),
        });
        setTime(selectedTime.format("HH:mm:ss"));

        form.setFieldsValue({
          time: selectedTime,
        });
        return;
      }
    }
    if (time.hour() === firstAppointmentAvailableHour) {
      if (time.minutes() < moment().minutes()) {
        let selectedTime = time.set({
          hours: firstAppointmentAvailableHour,
          minute: getMinutes(moment().minutes()),
        });
        setTime(selectedTime.format("HH:mm:ss"));

        form.setFieldsValue({
          time: selectedTime,
        });
        return;
      }

      let selectedTime = time.set({
        hours: firstAppointmentAvailableHour,
        minute: getMinutes(time.minutes()),
      });

      setTime(selectedTime.format("HH:mm:ss"));

      form.setFieldsValue({
        time: selectedTime,
      });
      return;
    }
    if (time.hour() > lastAppointmentEnabledHour) {
      let selectedTime = time.set({
        hours: lastAppointmentEnabledHour,
        minute: 0,
      });
      setTime(selectedTime.format("HH:mm:ss"));

      form.setFieldsValue({
        time: selectedTime,
      });
      return;
    }

    if (time.hour() < firstAppointmentAvailableHour) {
      let selectedTime = time.set({
        hours:
          moment().minutes() > 57
            ? firstAppointmentAvailableHour + 1
            : firstAppointmentAvailableHour,
        minute: getMinutes(moment().minutes()),
      });
      setTime(selectedTime.format("HH:mm:ss"));

      form.setFieldsValue({
        time: selectedTime,
      });
      return;
    }
    if (time.hour() === lastAppointmentEnabledHour) {
      let selectedTime = time.set({
        hours: time.hour(),
        minute: 0,
      });
      setTime(selectedTime.format("HH:mm:ss"));

      form.setFieldsValue({
        time: selectedTime,
      });
      return;
    }
  }
  if (time.hour() === firstAppointmentEnabledHour) {
    let selectedTime = time.set({
      hours: firstAppointmentEnabledHour,
    });
    setTime(selectedTime.format("HH:mm:ss"));

    form.setFieldsValue({
      time: selectedTime,
    });
    return;
  }
  if (time.hour() < firstAppointmentEnabledHour) {
    let selectedTime = time.set({
      hours: firstAppointmentEnabledHour,
      minute: firstAppointmentEnabledMinutes,
    });
    setTime(selectedTime.format("HH:mm:ss"));

    form.setFieldsValue({
      time: selectedTime,
    });

    return;
  }
  if (
    time.hour() > lastAppointmentEnabledHour ||
    time.hour() === lastAppointmentEnabledHour
  ) {
    let selectedTime = time.set({
      hours: lastAppointmentEnabledHour,
      minute: lastAppointmentEnabledMinutes,
    });
    setTime(selectedTime.format("HH:mm:ss"));

    form.setFieldsValue({
      time: selectedTime,
    });
    return;
  }

  setTime(time.format("HH:mm:ss"));
  form.setFieldsValue({
    time: time,
  });
};

export const onSaveCandidateModal = ({
  ModalInfo,
  onClose,
  setIsApproveModalOpen,
  setCandidateModalInfo,
  providerApi,
  openNotification,
  queryClient,
}) => {
  const { status, id, note } = ModalInfo;
  if (status === "b2b_qualified") {
    onClose(false);
    setIsApproveModalOpen(true);
    return;
  }
  providerApi
    .updateOneProvider({ id, note, status })
    .then((res) => {
      openNotification(
        "Update Provider",
        "Provider successfully updated",
        "success"
      );
      onClose(false);
      setCandidateModalInfo({
        status: null,
        note: "",
        showModal: false,
        id: null,
      });
      queryClient.invalidateQueries("providers");
    })
    .catch((err) => {
      openNotification("Update Location", err.response.data.message, "error");
    });
};

export const getDisabledHours = (enabledDays, selectedDay, timeFormat) => {
  const availbleTimeRange = enabledDays[selectedDay.day];
  if (!availbleTimeRange) return;
  const firstAppointmentEnabledHour = moment(
    availbleTimeRange?.start,
    timeFormat
  ).hour();
  const lastAppointmentEnabledHour = moment(
    availbleTimeRange?.end,
    timeFormat
  ).hour();
  const hoursPerDay = 24;
  let hours = [];
  const availbleHours = enabledDays[selectedDay?.day];
  if (!availbleHours) return;
  for (let i = 0; i < firstAppointmentEnabledHour; i++) {
    hours.push(i);
  }
  for (let i = lastAppointmentEnabledHour + 1; i <= hoursPerDay; i++) {
    hours.push(i);
  }
  if (selectedDay?.date?.diff(moment(), "hours") === 0) {
    for (
      let i = firstAppointmentEnabledHour;
      i <= lastAppointmentEnabledHour;
      i++
    ) {
      if (i < moment().hours() + ADDED_MARGIN_H) {
        hours.push(i);
      }
      if (i === moment().hours() + ADDED_MARGIN_H && moment().minutes() > 45) {
        hours.push(i);
      }
    }
  }
  return hours;
};

export const getDisabledMinutes = (
  selectedHour,
  enabledDays,
  selectedDay,
  timeFormat,
  dateFormat
) => {
  const availbleTimeRange = enabledDays[selectedDay.day];
  const firstAppointmentEnabledTime = moment(
    availbleTimeRange?.start,
    timeFormat
  );
  const lastAppointmentEnabledTime = moment(availbleTimeRange?.end, timeFormat);
  if (!availbleTimeRange) return;
  let minutes = [];
  if (selectedHour < 0) {
    for (let i = 0; i < 60; i++) {
      minutes.push(i);
    }
  } else if (selectedHour === firstAppointmentEnabledTime.hour()) {
    for (let i = firstAppointmentEnabledTime.minute() - 1; i >= 0; i--) {
      minutes.push(i);
    }
  } else if (selectedHour === lastAppointmentEnabledTime.hour()) {
    for (let i = lastAppointmentEnabledTime.minute() + 1; i <= 60; i++) {
      minutes.push(i);
    }
  }
  if (moment().format(dateFormat) === selectedDay.date.format(dateFormat)) {
    if (selectedHour === moment().hour() + ADDED_MARGIN_H) {
      for (let i = 0; i < moment().minute(); i++) {
        minutes.push(i);
      }
    }
  }

  return minutes;
};
