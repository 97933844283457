import React from "react";
import "antd/es/card/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";
import { Modal } from "../../atoms/modals/antd-modals";
import { Button } from "../../atoms/buttons/buttons";
import styles from "./style.module.scss";
import { Avatar, Badge, List, Table } from "antd";
import { StyledCheckbox } from "../../atoms/input/inputs";
import FeatherIcon from "feather-icons-react";
import { includes, filter, map, isEmpty, groupBy, get } from "lodash";
import appointmentApi from "../../../utilities/api/appointment";
import useGetWaivableFees from "../../../utilities/hooks/useGetWaivableFees";
import moment from "moment-timezone";
import openNotification from "../../../utilities/openNotification";
import { useQueryClient } from "react-query";

function WaiveFeesModal({ isOpen, onExit, appointmentId }) {
    const queryClient = useQueryClient();
    const [totalAmountToWaive, setTotalAmountToWaive] = React.useState(0);
    const [itemsToWaive, setItemsToWaive] = React.useState([]);
    const { cancellations, isLoading, refetch } = useGetWaivableFees(appointmentId, true);
    const checkboxHandler = (idd, fee = 0) => {
        let feeNumber = Number(fee);
        if (!includes(itemsToWaive, idd)) {
            setItemsToWaive([...itemsToWaive, idd]);
            setTotalAmountToWaive(totalAmountToWaive + feeNumber);
        } else {
            setItemsToWaive(filter(itemsToWaive, (elm) => elm !== idd));
            setTotalAmountToWaive(totalAmountToWaive - feeNumber);
        }
    };
    const onWaiveSubmit = () => {
        const isAllWaived = cancellations.every((elm) => elm.waived_fee);
        if (isEmpty(itemsToWaive) || isAllWaived) {
            return;
        }
        appointmentApi.waiveFeeById(itemsToWaive).then(() => {
            refetch && refetch();
            queryClient.refetchQueries("getWaivableFees");
            openNotification(
                "Success",
                "Selected fees have been waived successfully",
                "success",
            );
            onExit();
        }).catch(({ response }) => {
            openNotification(
                "Error",
                response?.data?.data[0]?.message || response?.data?.message,
                "error"
            );
        });;
    };
    const columnsProviderFees = [
        {
            dataIndex: "provider",
            key: "provider",
        }, {
            dataIndex: "date",
            key: "date",
        },
        {
            dataIndex: "cancelation_fee",
            key: "cancelation_fee",
        }, {
            dataIndex: "checkbox",
            key: "checkbox",
            align: "right"
        }
    ];
    const formulateCopy = (itm) => {
        const reasonType = itm?.cancellation_type;
        if (includes(reasonType, "reschedule")) {
            return `Late reschedule within ${itm?.time_before_booking} hours`;
        }
        return `Late cancellation within ${itm?.time_before_booking} hours`;
    }
    const generateDataSourceProviderFees = (provider_id) => {
        const itemsByPro = filter(cancellations, { pro_id: provider_id });
        return itemsByPro?.map((itm, ind) => {
            const sameYearAsCurrent = moment(itm.cancelled_at).isSame(moment(), "year");
            return {
                key: itm.id,
                provider: `${ind + 1}. ${formulateCopy(itm)}`,
                cancelation_fee: itm.formatted_client_fee,
                date: `${moment(itm.cancelled_at).format(sameYearAsCurrent ? "MMM DD" : "MMM DD, YYYY")}`,
                checkbox: itm.waived_fee ? <span style={{
                    color: "#545454",
                    borderRadius: "4px",
                    backgroundColor: "#F6F6F6",
                    padding: "4px 8px",
                    fontSize: "14px",
                    fontWeight: 500,
                    lineHeight: "16px",
                    textAlign: "center"
                }}>Waived</span> :
                    <StyledCheckbox id={`chkbx-${provider_id}-${ind}`} defaultChecked={includes(itemsToWaive, itm.id)} onChange={(e) => checkboxHandler(itm.id, itm?.calculated_client_fee)} />
            }
        });
    };
    const relevantFeesList = (provider) => {
        return (
            <div>
                <Table
                    className="tbl-no-border-btm mb-16 p-0"
                    showHeader={false}
                    pagination={false}
                    dataSource={generateDataSourceProviderFees(provider.provider_id)}
                    columns={columnsProviderFees}
                />
            </div>
        );
    }

    let isAllWaived = cancellations?.every((item) => item.waived_fee);
    let grpdList = groupBy(cancellations, "pro_id");
    let proObjs = map(grpdList, (value) => ({
        ...get(value, "0.pro", {}),
        provider_id: get(value, "0.pro.id", ""),
        name: `${get(value, "0.pro.first_name", "")} ${get(value, "0.pro.last_name", "")}`,
    }));

    return (
        <Modal
            wrapClassName={styles["modal-container"]}
            title={
                !isLoading && 
                <>{isAllWaived ?
                    <div>All fees have been waived</div>
                    : <>
                        <div>Waive Cancellation Fees</div>
                        <div style={{ color: "#272B41", fontSize: "16px", lineHeight: "24px", fontWeight: "normal" }}>Select the Providers you'd like to waive cancellation fees for.</div>
                    </>}
                </>
            }
            visible={isOpen}
            footer={null}
            onCancel={onExit}
            centered
        >
            {isLoading ? (
                <Skeleton active paragraph={{ rows: 9 }} />
            ) : (
                <div className="view-modal-body">
                    <div className="view-modal-body-card" style={{ marginRight: 0 }}>
                        <div>
                            <List
                                dataSource={proObjs}
                                renderItem={(item) => {
                                    let status = item?.status;
                                    return (
                                        <List.Item>
                                            <List.Item.Meta style={{ padding: "0px", borderBottom: "none" }}
                                                avatar={<Badge
                                                    offset={[-5, 24]}
                                                    style={{
                                                        backgroundColor:
                                                            status === "Offer Expired"
                                                                ? "#AFAFAF"
                                                                : status === "pending"
                                                                    ? "#FFC043"
                                                                    : status === "rejected"
                                                                        ? "#AFAFAF"
                                                                        : status === "cancelled"
                                                                            ? "#FF4D4F"
                                                                            : status === "error"
                                                                                ? "#FF4D4F"
                                                                                : "#20C997",
                                                        borderRadius: "50%",
                                                    }}
                                                    count={
                                                        status === "pending" ? (
                                                            <FeatherIcon
                                                                icon="more-horizontal"
                                                                color="#fff"
                                                                size={14}
                                                            />
                                                        ) : status === "Offer Expired" ||
                                                            status === "error" ||
                                                            status === "rejected" ||
                                                            status === "cancelled" ? (
                                                            <FeatherIcon icon="x" color="#fff" size={14} />
                                                        ) : (
                                                            <FeatherIcon icon="check" color="#fff" size={14} />
                                                        )
                                                    }
                                                ><Avatar src={item?.image} style={{
                                                    width: "30px", height: "30px", borderRadius: "30px", border: "1px solid #E2E2E2"
                                                }} />
                                                </Badge>}
                                                title={<span style={{ fontSize: "16px", lineHeight: "24px", fontWeight: "normal" }}>{item?.name}</span>}
                                                description={relevantFeesList(item)}
                                            />
                                        </List.Item>)
                                }}
                            />
                        </div>
                        {isAllWaived ? null
                            : <div style={{ fontWeight: 500, textAlign: "end" }}>
                                Total fees to waive: ${totalAmountToWaive}
                            </div>}
                        <div>
                            <footer>
                                {isAllWaived ?
                                    <Button key={1} size="small" onClick={onExit} type="light" className="not-red">
                                        Close
                                    </Button>
                                    : <>
                                        <Button key={1} size="small" onClick={onExit} type="light">
                                            Go back without saving
                                        </Button>

                                        <Button
                                            style={{ margin: 0 }}
                                            key={2}
                                            size="small"
                                            onClick={onWaiveSubmit}
                                            type="sootheButton"
                                        >
                                            Waive cancellation fees
                                        </Button>
                                    </>
                                }
                            </footer>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
}

export default WaiveFeesModal;
