import moment from "moment-timezone";
import { DEFAULT_END_HOUR, DEFAULT_START_HOUR } from "../../../utilities/constants";

export const onSelectHourFrom = ({
  time,
  availableShiftTime,
  selectedDay,
  shiftHours,
}) => {
  // if (time.hour() === moment(availableShiftTime.start, "HH:mm").hour()) {
  //   return time
  //     .set({
  //       hours: moment(availableShiftTime.start, "HH:mm").hour(),
  //       minute:
  //         time.minute() < moment(availableShiftTime.start, "HH:mm").minute()
  //           ? moment(availableShiftTime.start, "HH:mm").minute()
  //           : time.minute(),
  //     })
  //     .format("HH:mm");
  // }
  // todo recheck
  if (
    time.hour() >= moment(shiftHours.end, "HH:mm").hour() &&
    moment(shiftHours.end, "HH:mm").hour() !== 0
  ) {
    return time
      .set({
        hours: moment(shiftHours.end, "HH:mm").minutes()
          ? moment(shiftHours.end, "HH:mm").hour()
          : moment(shiftHours.end, "HH:mm").hour() - 1,
        minute: moment(shiftHours.end, "HH:mm").minutes()
          ? time.minutes() >= moment(shiftHours.end, "HH:mm").minutes()
            ? moment(shiftHours.end, "HH:mm").minutes() - 15
            : time.minutes()
          : time.minutes(),
      })
      .format("HH:mm");
  }

  if (time.hour() < DEFAULT_START_HOUR) {
    return time
      .set({
        hours: DEFAULT_START_HOUR,
        minute: 0,
      })
      .format("HH:mm");
  }

  // if (time.hour() < moment(availableShiftTime.start, "HH:mm").hour()) {
  // 	return time
  // 		.set({
  // 			hours: moment(availableShiftTime.start, "HH:mm").hour(),
  // 			minute: moment(availableShiftTime.start, "HH:mm").minute(),
  // 		})
  // 		.format("HH:mm");
  // }

  // if (time.hour() >= moment(availableShiftTime.end, "HH:mm").hour()) {
  //   if (
  //     time.hour() >= moment(shiftHours.end, "HH:mm").hour() &&
  //     moment(shiftHours.end, "HH:mm").hour() !== 0
  //   ) {
  //     return time
  //       .set({
  //         hours: moment(shiftHours.end, "HH:mm").hour() - 1,
  //         minute: 0,
  //       })
  //       .format("HH:mm");
  //   }
  //   return time
  //     .set({
  //       hours: moment(availableShiftTime.end, "HH:mm").hour() - 1,
  //       minute: 0,
  //     })
  //     .format("HH:mm");
  // }

  return time.format("HH:mm");
};

export const onChangeHourFrom = (time, setLastDisabledHours) => {
  let disabled = [];
  for (let i = 0; i <= moment(time).hours(); i++) {
    disabled.push(i);
  }
  setLastDisabledHours(disabled);
};

export const onChangeHourTill = (time, setFirstDisabledHours) => {
  let disabled = [];
  for (let i = moment(time).hours(); i < DEFAULT_END_HOUR; i++) {
    disabled.push(i);
  }
  setFirstDisabledHours(disabled);
};

export const onSelectHourTill = ({
  time,
  availableShiftTime,
  shiftHours,
  selectedDay,
}) => {
  const dateFormat = "YYYY-MM-DD";
  const timeFormat = "hh:mm";
  const firstAppointmentAvailableHour = shiftHours.start.hours();
  const firstAppointmentAvailableMins = shiftHours.start.minutes();

  // const lastAppointmentEnabledHour = moment(
  //   availableShiftTime.end,
  //   timeFormat
  // ).hour();
  // const lastAppointmentEnabledMinutes = moment(
  //   availableShiftTime.end,
  //   timeFormat
  // ).minute();

  if (time.hour() <= firstAppointmentAvailableHour) {
    time
      .set({
        hours: firstAppointmentAvailableHour,
        minutes: firstAppointmentAvailableMins
          ? time.minutes() <= firstAppointmentAvailableMins
            ? firstAppointmentAvailableMins + 15
            : time.minutes()
          : time.minutes() === 0
          ? 15
          : time.minutes(),
      })
      .format("HH:mm");
  }

  if (time.hour() > DEFAULT_END_HOUR) {
    time.set({
      hours: DEFAULT_END_HOUR,
      minutes: 0,
    });
    return;
  }

  // if (time.hour() > lastAppointmentEnabledHour) {
  //   time.set({
  //     hours: lastAppointmentEnabledHour,
  //     minutes: lastAppointmentEnabledMinutes,
  //   });
  //   return;
  // }

  // if (time.hour() === lastAppointmentEnabledHour) {
  //   time.set({
  //     hours: time.hour(),
  //     minute: lastAppointmentEnabledMinutes,
  //   });
  //   return;
  // }

  // if (time.hour() >= moment(availableShiftTime.end, "HH:mm").hour()) {
  //   time
  //     .set({
  //       hours: moment(availableShiftTime.end, "HH:mm").hour(),
  //       minute: moment(availableShiftTime.end, "HH:mm").minute(),
  //     })
  //     .format("HH:mm");
  // }
  time.format("HH:mm");
};

export const fromDisabledTime = ({
  availableShiftTime,
  firstDisabledHours,
  fixedDisabledHours,
  endShiftTime,
  selectedDay,
  startShiftTime,
}) => {
  return {
    disabledHours: () => {
      let disabled = [...firstDisabledHours];

      for (
        let i = moment(endShiftTime, "hh:mm").minutes()
          ? moment(endShiftTime, "hh:mm").hours() + 1
          : moment(endShiftTime, "hh:mm").hours();
        i <= DEFAULT_END_HOUR;
        i++
      ) {
        disabled.push(i);
      }
      // for (
      //   let i = moment(availableShiftTime.end, "hh:mm").hours();
      //   i <= DEFAULT_END_HOUR;
      //   i++
      // ) {
      //   disabled.push(i);
      // }

      // if (moment(availableShiftTime.start, "hh:mm").hours() > DEFAULT_START_HOUR) {
      // for (
      //   let i = DEFAULT_START_HOUR;
      //   i < moment(availableShiftTime.start, "hh:mm").hours();
      //   i++
      //   ) {
      //     disabled.push(i);
      //   }
      // }

      // for (
      //   let i = endShiftTime.minutes()
      //     ? endShiftTime.hours() + 1
      //     : endShiftTime.hours();
      //   i <= moment(availableShiftTime.end, "hh:mm").hours();
      //   i++
      // ) {
      //   if (endShiftTime.hours() !== 0) {
      //     disabled.push(i);
      //   }
      // }

      return fixedDisabledHours.concat(disabled);
    },
    disabledMinutes: (selectedHour) => {
      const timeFormat = "hh:mm";
      const dateFormat = "YYYY-MM-DD";
      const availbleTimeRange = availableShiftTime;
      const firstAppointmentEnabledTime = moment(
        availbleTimeRange?.start,
        timeFormat
      );
      const lastAppointmentEnabledTime = moment(
        availbleTimeRange?.end,
        timeFormat
      );
      if (!availbleTimeRange) return;
      let minutes = [];
      if (selectedHour < 0) {
        for (let i = 0; i < 60; i++) {
          minutes.push(i);
        }
      } else if (selectedHour === endShiftTime.hour()) {
        for (let i = endShiftTime.minute(); i <= 60; i++) {
          minutes.push(i);
        }
      } else if (selectedHour === firstAppointmentEnabledTime.hour()) {
        for (let i = firstAppointmentEnabledTime.minute() - 1; i >= 0; i--) {
          minutes.push(i);
        }
      } else if (selectedHour === lastAppointmentEnabledTime.hour()) {
        for (let i = lastAppointmentEnabledTime.minute() + 1; i <= 60; i++) {
          minutes.push(i);
        }
      }

      return minutes;
    },
  };
};

export const lastDisabledTime = ({
  availableShiftTime,
  lastDisabledHours,
  fixedDisabledHours,
  startShiftTime,
  endShiftTime,
  selectedDay,
}) => {
  return {
    disabledHours: () => {
      let disabled = [...lastDisabledHours];

      for (
        let i = DEFAULT_START_HOUR;
        i <
        (moment(startShiftTime, "hh:mm").minutes() < 45
          ? moment(startShiftTime, "hh:mm").hours()
          : moment(startShiftTime, "hh:mm").hours() + 1);
        i++
      ) {
        disabled.push(i);
      }

      // for (
      //   let i = DEFAULT_START_HOUR;
      //   i <= moment(availableShiftTime.start, "hh:mm").hours();
      //   i++
      // ) {
      //   disabled.push(i);
      // }

      // for (
      //   let i = moment(availableShiftTime.end, "hh:mm").hours() + 1;
      //   i <= DEFAULT_END_HOUR;
      //   i++
      // ) {
      //   disabled.push(i);
      // }

      // for (
      //   let i = moment(availableShiftTime.start, "hh:mm").hours();
      //   i < startShiftTime.hours();
      //   i++
      // ) {
      //   if (
      //     startShiftTime.hours() !== 0
      //   )
      //     disabled.push(i);
      // }
      return fixedDisabledHours.concat(disabled);
    },
    disabledMinutes: (selectedHour) => {
      const timeFormat = "hh:mm";
      const dateFormat = "YYYY-MM-DD";
      const availbleTimeRange = availableShiftTime;
      // const firstAppointmentEnabledTime = moment(
      //   availbleTimeRange?.start,
      //   timeFormat
      // );
      // const lastAppointmentEnabledTime = moment(
      //   availbleTimeRange?.end,
      //   timeFormat
      // );
      if (!availbleTimeRange) return;
      let minutes = [];

      if (selectedHour < 0) {
        for (let i = 0; i < 60; i++) {
          minutes.push(i);
        }
      } else if (selectedHour === startShiftTime.hour()) {
        for (let i = startShiftTime.minute(); i >= 0; i--) {
          minutes.push(i);
        }
        // } else if (selectedHour === firstAppointmentEnabledTime.hour()) {
        //   for (let i = firstAppointmentEnabledTime.minute() - 1; i >= 0; i--) {
        //     minutes.push(i);
        //   }
        // } else if (selectedHour === lastAppointmentEnabledTime.hour()) {
        //   for (let i = lastAppointmentEnabledTime.minute() + 1; i <= 60; i++) {
        //     minutes.push(i);
        //   }
      }

      return minutes;
    },
  };
};
