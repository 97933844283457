import React from "react";
import { Modal } from "../../atoms/modals/antd-modals";
import { Button } from "../../atoms/buttons/buttons";
import styles from "./style.module.scss"

function FeeModal({ isOpen, onExit, onSubmit, loading, title, body, action, submissionCopy }) {
  return (
    <Modal
      wrapClassName={`${styles["modal"]} ${styles["navy-modal"]}`}
      title={title}
      visible={isOpen}
      onCancel={onExit}
      centered
      footer={[
        <Button
          className="my-10"
          key="cancel"
          size="small"
          onClick={() => {
            onExit();
          }}
          type="light"
        >
          No, go back now
        </Button>,
        <Button
          key="delete"
          size="small"
          type="redButton"
          htmlType="submit"
          loading={loading}
          onClick={() => {
            onSubmit();
          }}
        >
          {action ? `Yes, ${action} appointment` : submissionCopy}
        </Button>,
      ]}
    >
      <div>{body}</div>
    </Modal>
  );
}

export default FeeModal;
