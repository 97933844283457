import { nodePortalAxiosInstance } from "./axiosInstance";

const appointmentEndpoints = {
  dashboard: "/dashboard_details",
  appointments: "/fetch_appointments",
  cancel_appointment: "/cancel_appointment",
  appointment_details: "/appointments",
  revenue: "/revenue_graph",
  cancellationReasons: "/client_cancellation_reasons",
  reschedule: "/reschedule",
  modify: "/change_appointment_time",
  addTip: "/add_tip",
  updateTip: "/update_tip",
  adjustShift: "/shift_slot_adjustment",
  convertToAllPros: "/convert_to_all_providers",
  getOfferProviders: "/get_appointment_offer_providers",
  getAppointmentProviders: "/get_appointment_providers",
  updateProviderNotes: "/update_provider_notes"
};

const appointment = {
  getAppointments({
    queryKey: [
      ,
      {
        locationId,
        fromSessionDate,
        toSessionDate,
        businessId,
        page,
        size,
        getAllBusinesses,
        status,
        search,
        bookingType,
      },
    ],
  }) {
    return nodePortalAxiosInstance.post(
      `${appointmentEndpoints.appointments}?page=${page}&per_page=${size}`,
      {
        appointment: {
          business_id: businessId,
          location_id: locationId,
          from_session_date: fromSessionDate,
          to_session_date: toSessionDate,
          get_all_businesses: getAllBusinesses,
          b2b_type: bookingType,
          status,
          search,
        },
      }
    );
  },
  getAppointmentsWithoutPagination({
    locationId,
    fromSessionDate,
    toSessionDate,
    businessId,
    page,
    size,
    exportType,
    withoutPagination = true,
    getAllBusinesses,
  }) {
    return nodePortalAxiosInstance.post(
      `${appointmentEndpoints.appointments}?page=${page}&per_page=${size}`,
      {
        appointment: {
          business_id: businessId,
          location_id: locationId,
          from_session_date: fromSessionDate,
          to_session_date: toSessionDate,
          without_pagination: withoutPagination,
          get_all_businesses: getAllBusinesses,
          exportType,
        },
      }, { responseType: "blob" }
    );
  },
  getDashboardAppointments({
    queryKey: [, { locationId, fromSessionDate, businessId }],
  }) {
    return nodePortalAxiosInstance.post(`${appointmentEndpoints.dashboard}`, {
      appointment: {
        business_id: businessId,
        location_id: locationId,
        from_session_date: fromSessionDate,
      },
    });
  },
  getDashboardRevenue({
    queryKey: [, { graphType, businessId, locationId, fromSessionDate }],
  }) {
    return nodePortalAxiosInstance.post(`${appointmentEndpoints.revenue}`, {
      graphType,
      appointment: {
        business_id: businessId,
        location_id: locationId,
        from_session_date: fromSessionDate,
      },
    });
  },
  getAppointmentDetails({ queryKey: [, { appointmentId }] }) {
    return nodePortalAxiosInstance.get(`${appointmentEndpoints.appointment_details}/${appointmentId}/show_appointment_details`);
  },
  getCancellationReasons(businessId) {
    return nodePortalAxiosInstance.get(`${appointmentEndpoints.cancellationReasons}`, {
      params: { businessId },
    });
  },
  rescheduleAppointment({
    id,
    businessId,
    locationId,
    session_date,
    session_time,
    rebook,
    quoted_fee,
    fee_reason
  }) {
    return nodePortalAxiosInstance.post(`${appointmentEndpoints.reschedule}?cart_id=${id}`, {
      "business_id": businessId,
      "location_id": locationId,
      "session_date": session_date,
      "session_time": session_time,
      "quoted_fee": quoted_fee,
      fee_reason,
      "rebook": rebook,
    });
  },
  modifyAppointment({
    id,
    businessId,
    locationId,
    session_date,
    session_time,
    rebook,
    quoted_fee,
    fee_reason,
    session_end_time
  }) {
    return nodePortalAxiosInstance.post(`${appointmentEndpoints.modify}?cart_id=${id}`, {
      "business_id": businessId,
      "location_id": locationId,
      "session_date": session_date,
      "session_time": session_time,
      "quoted_fee": quoted_fee,
      fee_reason,
      "rebook": rebook,
      session_end_time
    });
  },
  deleteAppointment({
    id,
    businessId,
    locationId,
    client_cancellation_reason_id,
    cancellation_reason_index,
    quoted_fee,
    fee_reason,
    custom_cancellation_reason
  }) {
    return nodePortalAxiosInstance.post(`${appointmentEndpoints.cancel_appointment}`, {
      "cart_id": id,
      "business_id": businessId,
      "location_id": locationId,
      "client_cancellation_reason_id": client_cancellation_reason_id,
      "cancellation_index": cancellation_reason_index,
      "quoted_fee": quoted_fee,
      fee_reason,
      custom_cancellation_reason
    });
  },
  addTip({
    location_id,
    cart_id,
    cart_product_id,
    percentage_of_original,
    amount,
  }) {
    return nodePortalAxiosInstance.post(`${appointmentEndpoints.addTip}`, {
      location_id,
      cart_id,
      cart_product_id,
      percentage_of_original,
      amount,
    });
  },
  updateTip({
    location_id,
    cart_id,
    cart_product_id,
    tip_id,
    percentage_of_original,
    amount,
  }) {
    return nodePortalAxiosInstance.post(`${appointmentEndpoints.updateTip}`, {
      location_id,
      cart_id,
      cart_product_id,
      tip_id,
      percentage_of_original,
      amount,
    });
  },
  adjustShift({
    businessId,
    locationId,
    breakMins,
    sessionTime,
    sessionEndTime,
    cartProductId,
    cartId,
    white_listed
  }) {
    return nodePortalAxiosInstance.post(`${appointmentEndpoints.adjustShift}`, {
      business_id: businessId,
      location_id: locationId,
      cart_id: cartId,
      cart_product_id: cartProductId,
      break_minutes: breakMins,
      session_time: sessionTime,
      session_end_time: sessionEndTime,
      white_listed
    });
  },
  convertToAllPros(appointment_id) {
    return nodePortalAxiosInstance.post(`/appointments/${appointment_id}${appointmentEndpoints.convertToAllPros}`)
  },
  getOfferProviders(appointment_id, page, per_page = 25, searchValue = "") {
    return nodePortalAxiosInstance.post(`/appointments/${appointment_id}${appointmentEndpoints.getOfferProviders}?page=${page}&per_page=${per_page}`, {
      ...searchValue && { name: searchValue }
    })
  },
  getAppointmentProviders(appointment_id) {
    return nodePortalAxiosInstance.post(`/appointments/${appointment_id}${appointmentEndpoints.getAppointmentProviders}`)
  },
  updateProviderNotes({ appointment_id, provider_notes }) {
    return nodePortalAxiosInstance.post(`/appointments/${appointment_id}${appointmentEndpoints.updateProviderNotes}`, {
      provider_notes
    })
  },
  getWaivableList({ queryKey: [, { cart_id, client_fee }] }) {
    return nodePortalAxiosInstance.get(`/cancellations`, {
      params: {
        cart_id,
        client_fee
      }
    });
  },
  waiveFeeById(cancellation_ids) {
    return nodePortalAxiosInstance.post(`/cancellations/waive_fee`, {
      cancellation_ids
    });
  },
};

export default appointment;
