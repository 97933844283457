import React, { useEffect, useMemo, useReducer, useRef, useState } from "react";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import Menu from "antd/es/menu";
import "antd/es/row/style";
import "antd/es/col/style";
import Collapse from "antd/es/collapse";
import "antd/es/collapse/style";
import Form from "antd/es/form";
import "antd/es/form/style";
import { capitalize, debounce, filter, find, findIndex, get, includes, intersection, isEmpty, isNull, isUndefined, map, set } from "lodash";
import moment from "moment-timezone";
import {
  Avatar,
  Badge,
  Card,
  Divider,
  Dropdown,
  Input,
  InputNumber,
  Modal,
  Rate,
  Select,
  Table,
  TimePicker,
} from "antd";
import "antd/es/table/style";
import Restricted from "../../../PermissionsProvider/Restricted";
import { Button } from "../../atoms/buttons/buttons";
import { useViewGlobal } from "../../../utilities/hooks/useView";
import styles from "./ViewAppointmentModal.module.scss";
import { usePermissions } from "../../../PermissionsProvider/PermissionsProvider";
import EllipsisLabel from "../../atoms/ellipsisLabel/EllipsisLabel";
import { DropdownBase, FlexRow } from "../../atoms";
import shiftApi from "../../../utilities/api/shift";
import appointmentsAPI from "../../../utilities/api/appointment";
import openNotification from "../../../utilities/openNotification";
import { useQueryClient } from "react-query";
import ConfirmModal from "../../organisms/ConfirmModal";
import {
  fromDisabledTime,
  lastDisabledTime,
  onSelectHourFrom,
  onSelectHourTill,
} from "../viewShift/helpers";
import WarningModal from "../../organisms/CreateShift/WarningModal";
import { ReactComponent as RadioButtonOutlined } from "../../../static/img/icon/radio_button_outlined.svg";
import { ReactComponent as RadioButtonFilled } from "../../../static/img/icon/radio_button.svg";
import CancelAppointmentModal from "./CancelAppointmentModal";
import ReasonsToCancelAppointmentModal from "./ReasonsToCancelAppointmentModal";

const { Panel } = Collapse;

function AppointmentOffers({
  appointment,
  refetch,
  data: { providers, workinghours },
  setIsLoading,
  workingDaysAndHours,
  selectDay,
  day,
  availableShiftTime,
  lastDisabledHours,
  fixedDisabledHours,
  firstDisabledHours,
  setTip,
  setAppointmentDetails,
  setUpdateTipDetails,
  isLoading,
  isSootheMember,
  should_show_warning,
  able_to_adjust,
  refetchWaivableFees,
}) {
  const queryClient = useQueryClient();
  const { view } = useViewGlobal();
  const { permissions, businessPermissions } = usePermissions();

  const [form] = Form.useForm();
  let {
    status,
    offers_json,
    providers_json,
    product_category,
    session_date,
    time,
    number_of_providers,
    unassigned_slots,
    partially_filled,
    b2b_type,
    cart_end_time,
    assigned_providers_count,
    is_training,
    offers_count,
    modality_slugs,
    location_name,
    product_id,
    modality_ids,
    appointment_number,
  } = appointment;

  useEffect(() => {
    if (appointment?.time?.timezone) {
      moment.tz.setDefault(appointment?.time?.timezone || "");
    }
  }, [appointment?.time?.timezone])

  const white_listed = get(appointment, "b2b_cart_details.white_listed_pros", false);

  const [selectedProviders, setSelectedProviders] = useState();
  const [searchValue, setSearchValue] = useState();
  const [page, setPage] = useState(1);
  const [totalOffers, setTotalOffers] = useState(offers_count);
  const [currentOffersJson, setCurrentOffersJson] = useState(offers_json);
  const [filteredProviders, setFilteredProviders] = useState(null);
  const [postShiftProviders, setPostShiftProviders] = useState(null);
  const [selectedPostShiftPro, setSelectedPostShiftPro] = useState();
  const [search, setSearch] = useState();
  const [slotUpdatePolicy, setSlotUpdatePolicy] = useState({
    copyType: "",
    shorten: false,
    isCancelModalOpen: false,
    slotId: null,
    submissionFunc: () => { }
  });
  const [isReasonsToCancelModalOpen, setIsReasonsToCancelModalOpen] = useState(false);
  const [selectedReasonForCancel, setSelectedReasonForCancel] = useState();
  const [otherReasonExplanation, setOtherReasonExplanation] = useState("");
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const [confirmModal, setConfirmModal] = useReducer(
    (prev, next) => ({ ...next }),
    {
      isOpen: false,
      action: "",
    }
  );
  const [confirmModal2, setConfirmModal2] = useReducer(
    (prev, next) => ({ ...next }),
    {
      isOpen: false,
      isResend: false
    }
  );
  const [warningModalState, setWarningModalState] = useState(null);

  useEffect(() => {
    if (!filteredProviders) {
      onAptProsFetch()
    }
  }, [filteredProviders]);

  useEffect(() => {
    if ((b2b_type === "staffing" || b2b_type === "corporate") && status === "completed" && able_to_adjust && !postShiftProviders) {
      postShiftFilteredProviders()
    }
  }, [postShiftProviders, providers_json]);
  const searchChangeHandler = () => {
    setSearch(searchValue?.toLowerCase() || "");
    setPage(1);
    onOfferProsFetch(page, searchValue);
  };

  const debouncedSearchChangeHandler = useMemo(
    () => debounce(searchChangeHandler, 500),
    [searchValue]
  );
  // Stop the invocation of the debounced function
  // after unmounting
  useEffect(() => {
    debouncedSearchChangeHandler();
    return () => {
      debouncedSearchChangeHandler.cancel();
    };
  }, [searchValue]);
  let offers_json_ids = currentOffersJson?.map((provider) => provider.provider_id);
  let providers_json_ids = providers_json?.map(
    (provider) => provider.provider_id
  );

  let datePassed =
    moment(moment.utc(session_date)).format("YYYY-MM-DD") <
    moment().format("YYYY-MM-DD");

  let timePassed =
    moment(moment.utc(session_date)).format("YYYY-MM-DD") ===
    moment().format("YYYY-MM-DD") &&
    moment(time?.display, ["h:mm A"]).format("HH:mm") <
    moment(new Date(), ["h:mm A"]).format("HH:mm");

  const onUpdateShiftProviders = (value, action, subPayload = {}) => {
    let refreshProsOffers = false;
    setIsLoading(true);

    let data = {
      business_id: appointment.business_id,
      location_id: appointment.location_id,
      cart_id: appointment.appointment_number,
      action: action,
      ...value,
    };

    let updateShiftFunction;
    switch (action) {
      case "assign_provider":
        updateShiftFunction =
          view === "admin" ? "assignProviderInAdminTab" : "assignProvider";
        refreshProsOffers = true;
        break;
      case "remove_provider":
        updateShiftFunction =
          view === "admin" ? "removeProviderInAdminTab" : "removeProvider";
        refreshProsOffers = true;
        break;
      case "resend_offer":
        updateShiftFunction =
          view === "admin" ? "resendOfferInAdminTab" : "resendOffer";
        break;
      case "send_offers":
        updateShiftFunction =
          view === "admin" ? "sendOffersInAdminTab" : "sendOffers";
        break;
      case "create_slot":
        updateShiftFunction =
          view === "admin" ? "createSlotInAdminTab" : "createSlot";
        break;
      case "remove_slot":
        updateShiftFunction =
          view === "admin" ? "removeSlotInAdminTab" : "removeSlot";
        break;
      case "remove_slot_and_provider":
        updateShiftFunction =
          view === "admin" ? "removeSlotInAdminTab" : "removeSlot";
        break;
      default:
        updateShiftFunction = "";
    }
    if (action === "remove_slot_and_provider" || action === "remove_slot" || action === "remove_provider") {
      const mainObj = value.cart_product_id ? find(appointment.cart_products, { cart_product_id: value.cart_product_id }) : appointment;
      data = {
        ...data,
        // client_cancellation_reason_id: selectedReasonForCancel === "other" ? "" : selectedReasonForCancel?.id,
        quoted_fee: get(mainObj, "cancellation_fee", null),
        fee_reason: get(mainObj, "cancel_fee_reason", null),
        // cancellation_reason_index: selectedReasonForCancel?.index,
        // custom_cancellation_reason: selectedReasonForCancel === "other" ? otherReasonExplanation : null,
        // additional_details: additionalDetails,
        ...subPayload
      }
    }
    shiftApi[updateShiftFunction](data)
      .then((response) => {
        openNotification("Success", response.data.data.message, "success");
        action === "send_offers" && setSelectedProviders();
        setIsLoading(false);
        if (action !== "resend_offer") {
          refetch();
          refetchWaivableFees();
          queryClient.refetchQueries("appointments");
          queryClient.refetchQueries("shifts");
          queryClient.refetchQueries("allBusinessesAppointments");
          queryClient.refetchQueries("businessAppointments");
          if (refreshProsOffers) {
            setPage(1)
            onOfferProsFetch(1, searchValue);
          }
          setSlotUpdatePolicy({
            ...slotUpdatePolicy,
            slotId: null
          })
          setAdditionalDetails("");
          setOtherReasonExplanation("");
          setSelectedReasonForCancel();
        }
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.status === 401 || error.response.status === 500) {
          openNotification("Error", error.response.data.message, "error");
        } else
          openNotification(
            "Error",
            error.response.data?.data[0].message,
            "error"
          );
      });
  };
  const onAssignProInPostShift = (value) => {
    setIsLoading(true);

    let data = {
      business_id: appointment.business_id,
      location_id: appointment.location_id,
      cart_id: appointment.appointment_number,
      action: "create_slot_and_assign_provider",
      ...value,
    };

    shiftApi.createSlotAndAssignProvider(data)
      .then((response) => {
        openNotification("Success", response.data.data.message, "success");
        setSelectedPostShiftPro();
        setPostShiftProviders();
        setIsLoading(false);
        refetch();
        refetchWaivableFees();
        queryClient.refetchQueries("appointments");
        queryClient.refetchQueries("shifts");
        queryClient.refetchQueries("allBusinessesAppointments");
        queryClient.refetchQueries("businessAppointments");
        setPage(1)
        onOfferProsFetch(1, searchValue);
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.status === 401 || error.response.status === 500) {
          openNotification("Error", error.response.data.message, "error");
        } else
          openNotification(
            "Error",
            error.response.data?.data[0].message,
            "error"
          );
      });

  }
  const postShiftFilteredProviders = () => {
    let details = {
      session_date: moment(session_date).format("YYYY-MM-DD"),
      location_id: appointment.location_id,
      session_time: moment(time?.display, "h:mm A").format("HH:mm"),
      session_end_time: moment(cart_end_time?.display, "h:mm A").format("HH:mm"),
      ...!isEmpty(modality_ids) && { option_ids: modality_ids || [] },
      service: product_id
    };

    shiftApi
      .getFilteredPros(details)
      .then((res) => {
        setPostShiftProviders(filter(get(res, "data.data.providers", []), (el) => (!includes(providers_json_ids, el.provider_id))));
      })
      .catch((err) => {
        openNotification("Error fetching providers", err.response.data.message, "error");
      });

  }

  const onExitConfirmModal = () => setConfirmModal({ isOpen: false });

  const handleMarkAsANoShow = () => {
    setConfirmModal({ isOpen: false });
    setIsLoading(true);

    let data = {
      business_id: appointment.business_id,
      location_id: appointment.location_id,
      cart_id: appointment.appointment_number,
      action: "mark_provider_as_no_show",
      pro_id: confirmModal.proId,
      cart_product_id: confirmModal.cartProductId,
    };

    shiftApi
      .markAsNowShow(data)
      .then((response) => {
        openNotification("Success", response.data.data.message, "success");
        refetch();
        refetchWaivableFees();
        queryClient.refetchQueries("shifts");
        queryClient.refetchQueries(["appointments"]);
        queryClient.refetchQueries("allBusinessesAppointments");
        queryClient.refetchQueries("businessAppointments");
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openNotification("Error", error.response.data.message, "error");
        } else
          openNotification(
            "Error",
            error.response.data?.data[0].message,
            "error"
          );
        setIsLoading(false);
      });
  };

  const handleShiftAdjustments = ({ cartProductId, start, end, breakMins }) => {
    setIsLoading(true);
    let data = {
      businessId: appointment.business_id,
      locationId: appointment.location_id,
      cartId: appointment.appointment_number,
      cartProductId,
      breakMins: breakMins,
      sessionTime: start,
      sessionEndTime: end,
    };
    appointmentsAPI
      .adjustShift(data)
      .then((res) => {
        refetch();
        refetchWaivableFees();
        queryClient.refetchQueries("appointments");
        queryClient.refetchQueries("shifts");
        queryClient.refetchQueries("allBusinessesAppointments");
        queryClient.refetchQueries("businessAppointments");
        // queryClient.invalidateQueries("location");
        openNotification("Success", res.data.data.result, "success");
        setIsLoading(false);
        setWarningModalState(null)
      })
      .catch(({ response }) => {
        setWarningModalState(null)
        setIsLoading(false);
        openNotification("Error", response.data.message, "error");
      });
  };
  const onOfferProsFetch = (page, searchValue) => {
    setIsLoading(true);
    appointmentsAPI
      .getOfferProviders(appointment.appointment_number, page, 25, searchValue)
      .then((res) => {
        setCurrentOffersJson(res?.data?.data?.providers);
        setTotalOffers(res?.data?.data?.total || 0);
        setIsLoading(false);
        inputRef?.current?.focus();
      })
      .catch((error) => {
        setIsLoading(false);
        inputRef?.current?.focus();
        openNotification("Error", error.response.data.message, "error");
      });
  }

  const onAptProsFetch = () => {
    setIsLoading(true);
    appointmentsAPI
      .getAppointmentProviders(appointment.appointment_number)
      .then((res) => {
        let pros = res?.data?.data?.providers?.filter((provider) => {
          const { service_categories, provider_id, is_training_needed } = provider;
          const isTrainingCategory = product_category?.toLowerCase() === "training";
          const isNotInOffersJsonIds = !offers_json_ids.includes(provider_id);
          const isNotInProvidersJsonIds = !providers_json_ids.includes(provider_id);
          const isOfSameModalities = isEmpty(modality_slugs) || !isEmpty(intersection(modality_slugs, provider.modalities));

          if (!isTrainingCategory) {
            return (
              service_categories.includes(product_category?.toLowerCase()) && isOfSameModalities && !is_training_needed
            );
          } else {
            return is_training_needed;
          }
        });
        setFilteredProviders(pros);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        openNotification("Error", error.response.data.message, "error");
      });
  }

  const menuItems = (provider, action, status) => {
    let cp = find(appointment.cart_products, { cart_product_id: provider?.cart_product_id });
    return action === "offers" && appointment.status !== "completed"
      ? [
        {
          label: (
            <Restricted
              both={view === "admin" ? false : true}
              to="assign_provider"
              resource={b2b_type === "corporate" ? "corporate" : "shift"}
              fallback={<></>}
            >
              <Link
                to="#"
                onClick={() =>
                  onUpdateShiftProviders(
                    { pro_id: provider.provider_id },
                    "assign_provider"
                  )
                }
              >
                <FeatherIcon icon="user-plus" size={18} />
                Assign this Provider
              </Link>
            </Restricted>
          ),
          key: 2,
        },
        {
          label: (
            <Restricted
              both={view === "admin" ? false : true}
              to="resend_offers"
              resource={b2b_type === "corporate" ? "corporate" : "shift"}
              fallback={<></>}
            >
              <Link
                to="#"
                onClick={() =>
                  onUpdateShiftProviders(
                    { pro_id: provider.provider_id },
                    "resend_offer"
                  )
                }
              >
                <FeatherIcon icon="send" size={18} />
                Resend offer
              </Link>
            </Restricted>
          ),
          key: 3,
        },
      ]
      : action === "confirmed"
        ? [
          ...(appointment.status === "completed" ? !able_to_adjust ? [] : [
            {
              label: (
                <Restricted
                  both={false}
                  to={`post_shift_remove_slot_and_provider_${b2b_type === "corporate" ? "corporate" : "staffing"}`}
                  resource={b2b_type === "corporate" ? "corporate" : "shift"}
                  fallback={<></>}
                >
                  <Link
                    to="#"
                    onClick={() => {
                      // if (cp?.cancel_fee_reason) {
                      setSlotUpdatePolicy({
                        copyType: "slot and provider",
                        isCancelModalOpen: Boolean(cp?.cancel_fee_reason),
                        slotId: provider.cart_product_id,
                        shorten: false,
                        submissionFunc: (subPayload) => onUpdateShiftProviders(
                          {
                            pro_id: provider.provider_id,
                            cart_product_id: provider.cart_product_id
                          },
                          "remove_slot_and_provider",
                          subPayload
                        )
                      })
                      setIsReasonsToCancelModalOpen(!Boolean(cp?.cancel_fee_reason))
                      // } else onUpdateShiftProviders(
                      //   {
                      //     pro_id: provider.provider_id,
                      //     cart_product_id: provider.cart_product_id,
                      //   },
                      //   "remove_slot_and_provider"
                      // )
                    }}
                  >
                    <FeatherIcon icon="user-minus" size={18} />
                    Remove Slot and Provider
                  </Link>
                </Restricted>
              ),
              key: 2,
            },
          ] : [{
            label: (
              <Restricted
                both={view === "admin" ? false : true}
                to="remove_provider"
                resource={b2b_type === "corporate" ? "corporate" : "shift"}
                fallback={<></>}
              >
                <Link
                  to="#"
                  onClick={() => {
                    // if (cp?.cancel_fee_reason) {
                    setSlotUpdatePolicy({
                      copyType: "provider",
                      isCancelModalOpen: Boolean(cp?.cancel_fee_reason),
                      slotId: provider.cart_product_id,
                      shorten: false,
                      submissionFunc: (subPayload) => onUpdateShiftProviders(
                        {
                          pro_id: provider.provider_id,
                          cart_product_id: provider.cart_product_id
                        },
                        "remove_provider",
                        subPayload
                      )
                    })
                    setIsReasonsToCancelModalOpen(!Boolean(cp?.cancel_fee_reason))
                    // } else onUpdateShiftProviders(
                    //   {
                    //     pro_id: provider.provider_id,
                    //     cart_product_id: provider.cart_product_id,
                    //   },
                    //   "remove_provider"
                    // )
                  }}
                >
                  <FeatherIcon icon="slash" size={18} />
                  Remove this Provider
                </Link>
              </Restricted>
            ),
            key: 2,
          },
          {
            label: (
              <Restricted
                both={view === "admin" ? false : true}
                to="remove_slot"
                resource={b2b_type === "corporate" ? "corporate" : "shift"}
                fallback={<></>}
              >
                <Link
                  to="#"
                  onClick={() => {
                    // if (cp?.cancel_fee_reason) {
                    setSlotUpdatePolicy({
                      copyType: "slot",
                      isCancelModalOpen: Boolean(cp?.cancel_fee_reason),
                      slotId: provider.cart_product_id,
                      shorten: false,
                      submissionFunc: (subPayload) => onUpdateShiftProviders(
                        {
                          pro_id: provider.provider_id,
                          cart_product_id: provider.cart_product_id
                        },
                        "remove_slot",
                        subPayload
                      )
                    })
                    setIsReasonsToCancelModalOpen(!Boolean(cp?.cancel_fee_reason))
                    // } else onUpdateShiftProviders(
                    //   {
                    //     pro_id: provider.provider_id,
                    //     cart_product_id: provider.cart_product_id,
                    //   },
                    //   "remove_slot"
                    // )
                  }}
                >
                  <FeatherIcon icon="user-minus" size={18} />
                  Remove this Slot
                </Link>
              </Restricted>
            ),
            key: 3,
          }]),
          ...(status === "no_show_up"
            ? []
            : [
              {
                label: (
                  <Restricted
                    to={appointment?.status === "completed" ?
                      `post_shift_mark_provider_as_no_show_${b2b_type === "corporate" ? "corporate" : "staffing"}`
                      : "mark_provider_as_no_show"}
                    resource={b2b_type === "corporate" ? "corporate" : "shift"}
                    both={appointment?.status === "completed" ? false : view === "admin" ? false : true}
                    fallback={<></>}
                  >
                    <Link
                      to="#"
                      onClick={() =>
                        setConfirmModal({
                          isOpen: true,
                          proId: provider.provider_id,
                          cartProductId: provider.cart_product_id,
                          proName: provider.name,
                        })
                      }
                    >
                      <FeatherIcon icon="x-circle" size={18} />
                      Mark as no-show
                    </Link>
                  </Restricted>
                ),
                key: 4,
              },
            ]),
        ]
        : appointment.status !== "completed" && (view === "admin"
          ? ((permissions?.shift?.includes("remove_slot") && appointment.is_staffing) || (permissions?.corporate?.includes("remove_slot") && appointment.is_corporate))
          : ((businessPermissions?.shift?.includes("remove_slot") &&
            permissions?.shift?.includes("remove_slot")) && appointment.is_staffing) || (businessPermissions?.corporate?.includes("remove_slot") && permissions?.corporate?.includes("remove_slot") && appointment.is_corporate)) ? [
          {
            label: (
              <Restricted
                both={view === "admin" ? false : true}
                to="remove_slot"
                resource={b2b_type === "corporate" ? "corporate" : "shift"}
                fallback={<></>}
              >
                <Link
                  to="#"
                  onClick={() => {
                    // if (cp?.cancel_fee_reason) {
                    setSlotUpdatePolicy({
                      copyType: "slot",
                      isCancelModalOpen: Boolean(cp?.cancel_fee_reason),
                      slotId: provider.cart_product_id,
                      shorten: false,
                      submissionFunc: (subPayload) => onUpdateShiftProviders(
                        { cart_product_id: provider.cart_product_id },
                        "remove_slot",
                        subPayload
                      )
                    })
                    setIsReasonsToCancelModalOpen(!Boolean(cp?.cancel_fee_reason))
                    // } else onUpdateShiftProviders(
                    //   { cart_product_id: provider.cart_product_id },
                    //   "remove_slot"
                    // )
                  }}
                >
                  <FeatherIcon icon="user-minus" size={18} />
                  Remove this Slot
                </Link>
              </Restricted>
            ),
            key: 2,
          },
        ] : [];
  };

  const unWhiteListApt = () => {
    setIsLoading(true);
    appointmentsAPI
      .convertToAllPros(appointment.appointment_number)
      .then((res) => {
        refetch();
        refetchWaivableFees();
        queryClient.refetchQueries("appointments");
        queryClient.refetchQueries("shifts");
        queryClient.refetchQueries("allBusinessesAppointments");
        queryClient.refetchQueries("businessAppointments");
        // queryClient.invalidateQueries("location");
        openNotification("Success", res.data.data.result, "success");
        setIsLoading(false);
      })
      .catch(({ response }) => {
        setIsLoading(false);
        openNotification("Error", response.data.message, "error");
      });
  }
  const WhosWorkingPanel = ({ title, disabled, data, action, unassigned, customKey }) => {
    let isStaffing = appointment.b2b_type === "staffing";
    let isCorporate = appointment.b2b_type === "corporate";
    let isConfirmed = action === "confirmed";
    let hasPermission =
      businessPermissions?.appointment?.includes("shift_adjustment") &&
      permissions?.appointment?.includes("shift_adjustment");
    // let hasPostShiftPermission =
    //   businessPermissions?.appointment?.includes("post_shift_adjustment") &&
    //   permissions?.appointment?.includes("post_shift_adjustment");
    let columns = [{
      dataIndex: "name",
      key: "name",
      title: "Providers",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }, {
      dataIndex: "status",
      key: "status",
      title: "Status",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }, {
      dataIndex: "shiftLength",
      key: "shiftLength",
      title: "Appointment Length",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`,
    }, {
      dataIndex: "breakLength",
      key: "breakLength",
      title: "Break Length",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`,
    }, {
      dataIndex: "tip",
      key: "tip",
      title: "Tip",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }, {
      dataIndex: "actions",
      key: "actions",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }];
    let secondColumns = [{
      dataIndex: "requestedProIndex",
      key: "requestedProIndex",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }, {
      dataIndex: "unassignedProTip",
      key: "unassignedProTip",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }, {
      dataIndex: "unassignedListActions",
      key: "unassignedListActions",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }]

    let tempDataSource = [];
    let secondTempDataSource = [];
    if (!isEmpty(data)) {
      data?.map((provider, index) => {
        let status = provider?.status;
        let shiftHours = action === "confirmed" && {
          start: moment(
            moment(provider?.start_time?.display, "h:mm A").format(
              "HH:mm"
            ),
            "hh:mm"
          ),
          end: moment(
            moment(provider?.end_time?.display, "h:mm A").format("HH:mm"),
            "hh:mm"
          ),
        };
        let cart_product = appointment.cart_products.filter(
          (cp) => cp.cart_product_id === provider.cart_product_id
        )[0];
        let sessionLength =
          moment
            .duration(
              moment(provider?.end_time?.display, "hh:mm a").diff(
                moment(provider?.start_time?.display, "hh:mm a"),
                "minutes"
              ),
              "minutes"
            )
            .asMinutes() - 1;
        tempDataSource.push({
          key: index,
          name: <div
            className={`${styles["flex-row"]} ${styles["pro-list"]}`}
          >
            <Badge
              offset={[-5, 24]}
              style={{
                backgroundColor:
                  status === "Offer Expired"
                    ? "#AFAFAF"
                    : status === "pending"
                      ? "#FFC043"
                      : status === "rejected"
                        ? "#AFAFAF"
                        : status === "cancelled"
                          ? "#FF4D4F"
                          : status === "error"
                            ? "#FF4D4F"
                            : "#20C997",
                borderRadius: "50%",
              }}
              count={
                status === "pending" ? (
                  <FeatherIcon
                    icon="more-horizontal"
                    color="#fff"
                    size={14}
                  />
                ) : status === "Offer Expired" ||
                  status === "error" ||
                  status === "rejected" ||
                  status === "cancelled" ? (
                  <FeatherIcon icon="x" color="#fff" size={14} />
                ) : (
                  <FeatherIcon icon="check" color="#fff" size={14} />
                )
              }
            >
              <Avatar key={index} src={provider.image} />
            </Badge>
            <EllipsisLabel
              style={{
                maxWidth:
                  isConfirmed && (isStaffing || isCorporate) && hasPermission
                    ? "100px"
                    : "150px",
                marginLeft: "5px",
                color:
                  status === "no_show_up" ? "#AFAFAF" : "#000000",
              }}
            >
              {provider.name ?? `Provider ${index + 1}`}
            </EllipsisLabel>
          </div>,
          status: <p
            style={{
              color: status === "no_show_up" ? "#AFAFAF" : "#000000",
            }}
          >
            {status === "no_show_up" ? "No-show" : capitalize(status)}
          </p>,
          shiftLength: status === "no_show_up" ? "" : <>
            {isConfirmed && (isStaffing || isCorporate) && hasPermission && appointment?.status !== "completed" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Form.Item
                  name={`start-${provider.cart_product_id}`}
                >
                  <TimePicker
                    className={styles["time-picker"]}
                    popupClassName={styles["panel-time-picker"]}
                    use12Hours
                    disabled={
                      !selectDay || !workingDaysAndHours[day] || (appointment?.status === "completed" && !able_to_adjust)
                    }
                    allowClear={false}
                    onChange={(time) => {
                      if (appointment?.status === "completed" && !able_to_adjust) {
                        return;
                      }
                      if (should_show_warning) {
                        setWarningModalState({
                          functn: () => {
                            handleShiftAdjustments({
                              cartProductId: provider.cart_product_id,
                              start: moment(time).format("HH:mm"),
                              end: moment(
                                provider.end_time.display,
                                "h:mm A"
                              ).format("HH:mm"),
                              breakMins: provider.break_minutes,
                            });
                          }
                        })
                      } else {
                        handleShiftAdjustments({
                          cartProductId: provider.cart_product_id,
                          start: moment(time).format("HH:mm"),
                          end: moment(
                            provider.end_time.display,
                            "h:mm A"
                          ).format("HH:mm"),
                          breakMins: provider.break_minutes,
                        });
                      }
                    }}
                    disabledTime={() =>
                      fromDisabledTime({
                        availableShiftTime,
                        endShiftTime: moment(
                          shiftHours.end,
                          "hh:mm A"
                        ),
                        startShiftTime: moment(
                          shiftHours.start,
                          "hh:mm A"
                        ),
                        firstDisabledHours,
                        fixedDisabledHours,
                        selectedDay: selectDay.date,
                      })
                    }
                    onSelect={(time) =>
                      onSelectHourFrom({
                        time,
                        availableShiftTime,
                        shiftHours,
                        selectedDay: selectDay.date,
                      })
                    }
                    showNow={false}
                    minuteStep={5}
                    inputReadOnly
                    format="h:mm A"
                    defaultValue={moment(
                      provider?.start_time ? provider?.start_time?.display : 0,
                      "hh:mm A"
                    )}
                  />
                </Form.Item>
                <span style={{ margin: "0 10px" }}>-</span>
                <Form.Item name={`end-${provider.cart_product_id}`}>
                  <TimePicker
                    className={styles["time-picker"]}
                    popupClassName={styles["panel-time-picker"]}
                    use12Hours
                    disabled={
                      !selectDay || !workingDaysAndHours[day] || (appointment?.status === "completed" && !able_to_adjust)
                    }
                    allowClear={false}
                    minuteStep={5}
                    disabledTime={() =>
                      lastDisabledTime({
                        availableShiftTime,
                        startShiftTime: moment(
                          shiftHours.start,
                          "hh:mm A"
                        ),
                        endShiftTime: moment(
                          shiftHours.end,
                          "hh:mm A"
                        ),
                        lastDisabledHours,
                        fixedDisabledHours,
                        selectedDay: selectDay.date,
                      })
                    }
                    onSelect={(time) =>
                      onSelectHourTill({
                        time,
                        availableShiftTime,
                        shiftHours,
                        selectedDay: selectDay.date,
                      })
                    }
                    onChange={(time) => {
                      if (appointment?.status === "completed" && !able_to_adjust) {
                        return;
                      }
                      if (should_show_warning) {
                        setWarningModalState({
                          functn: () => {
                            handleShiftAdjustments({
                              cartProductId: provider.cart_product_id,
                              start: moment(
                                provider.start_time.display,
                                "h:mm A"
                              ).format("HH:mm"),
                              end: moment(time).format("HH:mm"),
                              breakMins: provider.break_minutes,
                            });
                          }
                        })
                      } else {

                        handleShiftAdjustments({
                          cartProductId: provider.cart_product_id,
                          start: moment(
                            provider.start_time.display,
                            "h:mm A"
                          ).format("HH:mm"),
                          end: moment(time).format("HH:mm"),
                          breakMins: provider.break_minutes,
                        });
                      }
                    }}
                    inputReadOnly
                    format="hh:mm A"
                    showNow={false}
                    defaultValue={moment(
                      provider?.end_time ? provider?.end_time?.display : 0,
                      "h:mm A"
                    )}
                  />
                </Form.Item>
              </div>
            )
              : (appointment.status === "completed"
                && able_to_adjust) ? <Restricted
                  both={false}
                  to={`post_shift_adjust_slot_time_${b2b_type === "corporate" ? "corporate" : "staffing"}`}
                  resource={b2b_type === "corporate" ? "corporate" : "shift"}
                  fallback={`${get(provider, 'start_time.display', '')} - ${get(provider, 'end_time.display', '')}`}
                >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Form.Item
                    name={`start-${provider.cart_product_id}`}
                  >
                    <TimePicker
                      className={styles["time-picker"]}
                      popupClassName={styles["panel-time-picker"]}
                      use12Hours
                      disabled={
                        !selectDay || !workingDaysAndHours[day] || (status === "complete" && !able_to_adjust)
                      }
                      allowClear={false}
                      onChange={(time) => {
                        if (should_show_warning) {
                          setWarningModalState({
                            functn: () => {
                              handleShiftAdjustments({
                                cartProductId: provider.cart_product_id,
                                start: moment(time).format("HH:mm"),
                                end: moment(
                                  provider.end_time.display,
                                  "h:mm A"
                                ).format("HH:mm"),
                                breakMins: provider.break_minutes,
                              });
                            }
                          })
                        } else {
                          handleShiftAdjustments({
                            cartProductId: provider.cart_product_id,
                            start: moment(time).format("HH:mm"),
                            end: moment(
                              provider.end_time.display,
                              "h:mm A"
                            ).format("HH:mm"),
                            breakMins: provider.break_minutes,
                          });
                        }
                      }}
                      disabledTime={() =>
                        fromDisabledTime({
                          availableShiftTime,
                          endShiftTime: moment(
                            shiftHours.end,
                            "hh:mm A"
                          ),
                          startShiftTime: moment(
                            shiftHours.start,
                            "hh:mm A"
                          ),
                          firstDisabledHours,
                          fixedDisabledHours,
                          selectedDay: selectDay.date,
                        })
                      }
                      onSelect={(time) =>
                        onSelectHourFrom({
                          time,
                          availableShiftTime,
                          shiftHours,
                          selectedDay: selectDay.date,
                        })
                      }
                      showNow={false}
                      minuteStep={5}
                      inputReadOnly
                      format="h:mm A"
                      defaultValue={moment(
                        provider?.start_time ? provider?.start_time?.display : 0,
                        "hh:mm A"
                      )}
                    />
                  </Form.Item>
                  <span style={{ margin: "0 10px" }}>-</span>
                  <Form.Item name={`end-${provider.cart_product_id}`}>
                    <TimePicker
                      className={styles["time-picker"]}
                      popupClassName={styles["panel-time-picker"]}
                      use12Hours
                      disabled={
                        !selectDay || !workingDaysAndHours[day] || (status === "complete" && !able_to_adjust)
                      }
                      allowClear={false}
                      minuteStep={5}
                      disabledTime={() =>
                        lastDisabledTime({
                          availableShiftTime,
                          startShiftTime: moment(
                            shiftHours.start,
                            "hh:mm A"
                          ),
                          endShiftTime: moment(
                            shiftHours.end,
                            "hh:mm A"
                          ),
                          lastDisabledHours,
                          fixedDisabledHours,
                          selectedDay: selectDay.date,
                        })
                      }
                      onSelect={(time) =>
                        onSelectHourTill({
                          time,
                          availableShiftTime,
                          shiftHours,
                          selectedDay: selectDay.date,
                        })
                      }
                      onChange={(time) => {
                        if (should_show_warning) {
                          setWarningModalState({
                            functn: () => {
                              handleShiftAdjustments({
                                cartProductId: provider.cart_product_id,
                                start: moment(
                                  provider.start_time.display,
                                  "h:mm A"
                                ).format("HH:mm"),
                                end: moment(time).format("HH:mm"),
                                breakMins: provider.break_minutes,
                              });
                            }
                          })
                        } else {

                          handleShiftAdjustments({
                            cartProductId: provider.cart_product_id,
                            start: moment(
                              provider.start_time.display,
                              "h:mm A"
                            ).format("HH:mm"),
                            end: moment(time).format("HH:mm"),
                            breakMins: provider.break_minutes,
                          });
                        }
                      }}
                      inputReadOnly
                      format="hh:mm A"
                      showNow={false}
                      defaultValue={moment(
                        provider?.end_time ? provider?.end_time?.display : 0,
                        "h:mm A"
                      )}
                    />
                  </Form.Item>
                </div>
              </Restricted>
                :
                `${get(provider, 'start_time.display', '')} - ${get(provider, 'end_time.display', '')}`
            }
          </>,
          breakLength: status === "no_show_up" ? "" : <>
            {isConfirmed &&
              (isStaffing || isCorporate) &&
              hasPermission && appointment.status !== "completed" &&
              action === "confirmed"
              ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between"
                  }}
                >
                  Break{" "}
                  <Dropdown
                    overlay={
                      <Menu
                        items={[
                          {
                            key: "1",
                            label: (
                              <Button
                                className={styles["save-btn"]}
                                onClick={() => {
                                  if (
                                    form.getFieldValue(
                                      `break-${provider.cart_product_id}`
                                    ) > sessionLength
                                  ) {
                                    openNotification(
                                      "Warning",
                                      `The break minutes should be less than the session length (${sessionLength})`,
                                      "warning"
                                    );
                                    return;
                                  } else if (
                                    form.getFieldValue(
                                      `break-${provider.cart_product_id}`
                                    ) < 0
                                  ) {
                                    openNotification(
                                      "Warning",
                                      `The break minutes can't be negative`,
                                      "warning"
                                    );
                                  } else {
                                    if (should_show_warning) {
                                      setWarningModalState({
                                        functn: () => {
                                          handleShiftAdjustments({
                                            cartProductId:
                                              provider.cart_product_id,
                                            start: moment(
                                              provider.start_time.display,
                                              "h:mm A"
                                            ).format("HH:mm"),
                                            end: moment(
                                              provider.end_time.display,
                                              "h:mm A"
                                            ).format("HH:mm"),
                                            breakMins: form.getFieldValue(
                                              `break-${provider.cart_product_id}`
                                            ),
                                          })
                                        }
                                      })
                                    } else {
                                      handleShiftAdjustments({
                                        cartProductId:
                                          provider.cart_product_id,
                                        start: moment(
                                          provider.start_time.display,
                                          "h:mm A"
                                        ).format("HH:mm"),
                                        end: moment(
                                          provider.end_time.display,
                                          "h:mm A"
                                        ).format("HH:mm"),
                                        breakMins: form.getFieldValue(
                                          `break-${provider.cart_product_id}`
                                        ),
                                      });
                                    }
                                  }
                                }}
                                type="light"
                              >
                                Save
                              </Button>
                            ),
                          },
                        ]}
                      />
                    }
                    overlayClassName={
                      styles["break-dropdown-overlay"]
                    }
                    placement="bottom"
                    trigger={["click"]}
                  >
                    <Form.Item
                      name={`break-${provider.cart_product_id}`}
                      rules={[
                        {
                          message: `The break minutes should be less than the session length (${sessionLength})`,
                          validator: (options, value) => {
                            if (
                              form.getFieldValue(
                                `break-${provider.cart_product_id}`
                              ) > sessionLength
                            ) {
                              openNotification(
                                "Warning",
                                `The break minutes should be less than the session length (${sessionLength})`,
                                "warning"
                              );
                              return;
                            } else if (
                              form.getFieldValue(
                                `break-${provider.cart_product_id}`
                              ) < 0
                            ) {
                              openNotification(
                                "Warning",
                                `The break minutes can't be negative`,
                                "warning"
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        },
                      ]}
                    >
                      <InputNumber
                        name="break-input"
                        id={`input-${index}`}
                        className={styles["break-input"]}
                        defaultValue={provider?.break_minutes
                          ? provider?.break_minutes
                          : 0}
                      // min={0}
                      // max={sessionLength}
                      />
                    </Form.Item>
                  </Dropdown>
                  <span
                    style={{
                      color: "#5F637A",
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "20px"
                    }}
                  >
                    {" "}<span style={{ color: "#5F637A" }}> min</span>
                  </span>
                </div>
              ) :
              appointment?.status === "completed" && able_to_adjust ?
                <Restricted
                  both={false}
                  to={`post_shift_adjust_slot_time_${b2b_type === "corporate" ? "corporate" : "staffing"}`}
                  resource={b2b_type === "corporate" ? "corporate" : "shift"}
                  fallback={`Break ${get(provider, "break_minutes", 0)} min`}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  >
                    Break{" "}
                    <Dropdown
                      overlay={
                        <Menu
                          items={[
                            {
                              key: "1",
                              label: (
                                <Button
                                  className={styles["save-btn"]}
                                  onClick={() => {
                                    if (
                                      form.getFieldValue(
                                        `break-${provider.cart_product_id}`
                                      ) > sessionLength
                                    ) {
                                      openNotification(
                                        "Warning",
                                        `The break minutes should be less than the session length (${sessionLength})`,
                                        "warning"
                                      );
                                      return;
                                    } else if (
                                      form.getFieldValue(
                                        `break-${provider.cart_product_id}`
                                      ) < 0
                                    ) {
                                      openNotification(
                                        "Warning",
                                        `The break minutes can't be negative`,
                                        "warning"
                                      );
                                    } else {
                                      if (should_show_warning) {
                                        setWarningModalState({
                                          functn: () => {
                                            handleShiftAdjustments({
                                              cartProductId:
                                                provider.cart_product_id,
                                              start: moment(
                                                provider.start_time.display,
                                                "h:mm A"
                                              ).format("HH:mm"),
                                              end: moment(
                                                provider.end_time.display,
                                                "h:mm A"
                                              ).format("HH:mm"),
                                              breakMins: form.getFieldValue(
                                                `break-${provider.cart_product_id}`
                                              ),
                                            })
                                          }
                                        })
                                      } else {
                                        handleShiftAdjustments({
                                          cartProductId:
                                            provider.cart_product_id,
                                          start: moment(
                                            provider.start_time.display,
                                            "h:mm A"
                                          ).format("HH:mm"),
                                          end: moment(
                                            provider.end_time.display,
                                            "h:mm A"
                                          ).format("HH:mm"),
                                          breakMins: form.getFieldValue(
                                            `break-${provider.cart_product_id}`
                                          ),
                                        });
                                      }
                                    }
                                  }}
                                  type="light"
                                >
                                  Save
                                </Button>
                              ),
                            },
                          ]}
                        />
                      }
                      overlayClassName={
                        styles["break-dropdown-overlay"]
                      }
                      placement="bottom"
                      trigger={["click"]}
                    >
                      <Form.Item
                        name={`break-${provider.cart_product_id}`}
                        rules={[
                          {
                            message: `The break minutes should be less than the session length (${sessionLength})`,
                            validator: (options, value) => {
                              if (
                                form.getFieldValue(
                                  `break-${provider.cart_product_id}`
                                ) > sessionLength
                              ) {
                                openNotification(
                                  "Warning",
                                  `The break minutes should be less than the session length (${sessionLength})`,
                                  "warning"
                                );
                                return;
                              } else if (
                                form.getFieldValue(
                                  `break-${provider.cart_product_id}`
                                ) < 0
                              ) {
                                openNotification(
                                  "Warning",
                                  `The break minutes can't be negative`,
                                  "warning"
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          },
                        ]}
                      >
                        <InputNumber
                          name="break-input"
                          id={`input-${index}`}
                          className={styles["break-input"]}
                          defaultValue={provider?.break_minutes
                            ? provider?.break_minutes
                            : 0}
                        // min={0}
                        // max={sessionLength}
                        />
                      </Form.Item>
                    </Dropdown>
                    <span
                      style={{
                        color: "#5F637A",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "20px"
                      }}
                    >
                      {" "}<span style={{ color: "#5F637A" }}> min</span>
                    </span>
                  </div>
                </Restricted>
                : (
                  `Break ${get(provider, "break_minutes", 0)} min`
                )}
          </>,
          tip: <Restricted
            to={appointment?.status === "completed" ?
              `post_shift_${cart_product?.edit_tip_allowed && !cart_product?.add_tip_allowed ? "edit"
                : !cart_product?.edit_tip_allowed && cart_product?.add_tip_allowed ? "add" : ""}_tip_${appointment.b2b_type === "corporate" ? "corporate" : "staffing"}`
              : "tip"}
            resource={appointment?.status === "completed" ?
              appointment.b2b_type === "corporate" ? "corporate" : "shift"
              : "appointment"}
            fallback={<span style={{ color: "#545454" }}>Tip {get(cart_product, "tip_amount", 0) || 0}<span style={{ color: "#5F637A" }}>$</span></span>}
          >
            <Link
              style={{
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => {
                let tipIndex = findIndex(appointment.cart_products, (el) => (el.cart_product_id === provider.cart_product_id));

                if (
                  status !== "cancelled" &&
                  cart_product.edit_tip_allowed &&
                  !cart_product.add_tip_allowed
                ) {
                  setTip({ isAddTip: false, isUpdateTip: true, isModalOpen: true, tipIndex });
                  setAppointmentDetails({
                    bookingType: capitalize(appointment.b2b_type),
                    clientName: cart_product.client_name,
                    providerName:
                      cart_product.provider_name || "Provider",
                    bookingTime: appointment.time.display,
                    location: location_name,
                    roomNumber:
                      cart_product.room_number || "Unassigned",
                    bookedBy: appointment.booked_by,
                  });
                  setUpdateTipDetails({
                    location_id: appointment?.location_id,
                    cart_id: appointment.appointment_number,
                    cart_product_id: cart_product.cart_product_id,
                    tip_id: cart_product.tip_id,
                  });
                } else if (
                  status !== "cancelled" &&
                  cart_product.add_tip_allowed &&
                  !cart_product.edit_tip_allowed
                ) {
                  setTip({ isAddTip: true, isUpdateTip: false, isModalOpen: true, tipIndex });
                  setAppointmentDetails({
                    bookingType: capitalize(appointment.b2b_type),
                    clientName: cart_product.client_name,
                    providerName:
                      cart_product.provider_name || "Provider",
                    bookingTime: appointment.time.display,
                    location: location_name,
                    roomNumber:
                      cart_product.room_number || "Unassigned",
                    bookedBy: appointment.booked_by,
                  });
                  setUpdateTipDetails({
                    location_id: appointment?.location_id,
                    cart_id: appointment.appointment_number,
                    cart_product_id: cart_product.cart_product_id,
                    tip_id: cart_product.tip_id,
                  });
                }
              }}
            >
              {status !== "cancelled" && (
                cart_product?.edit_tip_allowed && !cart_product?.add_tip_allowed) ? (
                <div className={styles["edit-tip"]}>
                  <span>Edit Tip</span>
                  <FeatherIcon icon="chevron-right" size={14} />
                </div>
              ) : status !== "cancelled" &&
                (cart_product?.add_tip_allowed && !cart_product?.edit_tip_allowed) ? (
                <div className={styles["add-tip"]}>
                  <span>Add Tip</span>
                  <FeatherIcon icon="chevron-right" size={14} />
                </div>
              ) :
                <span
                  style={{ color: "#545454" }}>Tip {get(cart_product, "tip_amount", 0) || 0}<span style={{ color: "#5F637A" }}>$</span></span>
              }
            </Link>
          </Restricted>,
          actions: appointment?.status === "completed" && !able_to_adjust ? null :
            !isEmpty(menuItems(
              provider,
              provider.unassigned ? "" : action,
              status
            )) && (
              <DropdownBase
                className="actions-dropdown"
                classNameC="actions-dropdown-content"
                disabled={disabled}
                action={["click"]}
                content={
                  <Menu
                    items={menuItems(
                      provider,
                      provider.unassigned ? "" : action,
                      status
                    )}
                  />
                }
              >
                <Link to="#">
                  <FeatherIcon
                    color="#ADB4D2"
                    icon="more-vertical"
                    size={18}
                    style={{
                      marginTop: "5px",
                    }}
                  />
                </Link>
              </DropdownBase>
            )
        })
      })
    }
    if (!isEmpty(unassigned)) {
      unassigned?.map((provider, index) => {
        let cart_product = appointment.cart_products.filter(
          (cp) => cp.cart_product_id === provider.cart_product_id
        )[0];
        secondTempDataSource.push({
          key: index,
          requestedProIndex: <div
            className={`${styles["flex-row"]} ${styles["pro-list"]}`}
          >
            <Avatar
              key={index}
              src={provider.image}
              style={{ backgroundColor: "#ADB4D2" }}
            />
            <p style={{ color: "#545454", marginLeft: "5px" }}>
              Requested Provider {index + 1} (Unassigned)
            </p>
          </div>,
          unassignedProTip: appointment?.status !== "cancelled" && (
            <Restricted
              to={appointment?.status === "completed" ?
                `post_shift_${cart_product?.edit_tip_allowed && !cart_product?.add_tip_allowed ? "edit"
                  : !cart_product?.edit_tip_allowed && cart_product?.add_tip_allowed ? "add" : ""}_tip_${appointment.b2b_type === "corporate" ? "corporate" : "staffing"}`
                : "tip"}
              resource={appointment?.status === "completed" ?
                appointment.b2b_type === "corporate" ? "corporate" : "shift"
                : "appointment"}
              fallback={<span style={{ color: "#545454" }}>Tip {get(cart_product, "tip_amount", 0) || 0}<span style={{ color: "#5F637A" }}>$</span></span>}
            >
              <Link
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
                onClick={() => {
                  let tipIndex = findIndex(appointment.cart_products, (el) => (el.cart_product_id === provider.cart_product_id));
                  if (
                    status !== "cancelled" &&
                    cart_product.edit_tip_allowed &&
                    !cart_product.add_tip_allowed
                  ) {
                    setTip({ isAddTip: false, isUpdateTip: true, isModalOpen: true, tipIndex });
                    setAppointmentDetails({
                      bookingType: capitalize(appointment.b2b_type),
                      clientName: cart_product.client_name,
                      providerName:
                        cart_product.provider_name || "Provider",
                      bookingTime: appointment.time.display,
                      location: location_name,
                      roomNumber:
                        cart_product.room_number || "Unassigned",
                      bookedBy: appointment.booked_by,
                    });
                    setUpdateTipDetails({
                      location_id: appointment?.location_id,
                      cart_id: appointment.appointment_number,
                      cart_product_id: cart_product.cart_product_id,
                      tip_id: cart_product.tip_id,
                    });
                  } else if (
                    status !== "cancelled" &&
                    cart_product.add_tip_allowed &&
                    !cart_product.edit_tip_allowed
                  ) {
                    setTip({ isAddTip: true, isUpdateTip: false, isModalOpen: true, tipIndex });
                    setAppointmentDetails({
                      bookingType: capitalize(appointment.b2b_type),
                      clientName: cart_product.client_name,
                      providerName:
                        cart_product.provider_name || "Provider",
                      bookingTime: appointment.time.display,
                      location: location_name,
                      roomNumber:
                        cart_product.room_number || "Unassigned",
                      bookedBy: appointment.booked_by,
                    });
                    setUpdateTipDetails({
                      location_id: appointment?.location_id,
                      cart_id: appointment.appointment_number,
                      cart_product_id: cart_product.cart_product_id,
                      tip_id: cart_product.tip_id,
                    });
                  }
                }}
              >
                {status !== "cancelled" &&
                  (cart_product?.edit_tip_allowed && !cart_product?.add_tip_allowed) ? (
                  <div className={styles["edit-tip"]}>
                    <span>Edit Tip</span>
                    <FeatherIcon icon="chevron-right" size={14} />
                  </div>
                ) : status !== "cancelled" &&
                  (cart_product?.add_tip_allowed && !cart_product?.edit_tip_allowed) ? (
                  <div className={styles["add-tip"]}>
                    <span>Add Tip</span>
                    <FeatherIcon icon="chevron-right" size={14} />
                  </div>
                ) : (
                  <span
                    style={{ color: "#545454" }}>Tip {get(cart_product, "tip_amount", 0) || 0}<span style={{ color: "#5F637A" }}>$</span></span>
                )}
              </Link>
            </Restricted>
          ),
          unassignedListActions: appointment?.status === "completed" && !able_to_adjust ? null
            : !isEmpty(menuItems({ cart_product_id: cart_product?.cart_product_id }, "")) && (
              <DropdownBase
                className="actions-dropdown"
                classNameC="actions-dropdown-content"
                disabled={disabled}
                action={["click"]}
                content={<Menu items={menuItems({ cart_product_id: cart_product?.cart_product_id }, "")} />}
              >
                <Link to="#">
                  <FeatherIcon
                    color="#ADB4D2"
                    icon="more-vertical"
                    size={18}
                    style={{
                      marginTop: "5px",
                    }}
                  />
                </Link>
              </DropdownBase>
            )
        })
      })
    }
    return (
      <Collapse
        bordered={false}
        defaultActiveKey={[customKey]}
        style={{ backgroundColor: "#fff" }}
      >
        <Panel header={title} key={customKey} style={{ marginLeft: "-8px" }}>
          {isEmpty(data) ? null : <Form form={form} name="shift-form" className={styles["shift-form"]}>
            <Table
              dataSource={tempDataSource}
              columns={columns}
              pagination={false}
            />
          </Form>}
          {isEmpty(unassigned) ? null : <Table
            dataSource={secondTempDataSource}
            columns={secondColumns}
            pagination={false}
          />}
        </Panel>
      </Collapse>)
  }
  const TablePanels = ({ title, disabled, data, action, customKey }) => {
    const generateDataSourceProviders = () => {
      const dataSource = [];
      if (data?.length) {
        data?.map((provider, index) => {
          let status = provider?.status;

          dataSource.push({
            key: index,
            name: <div
              className={`${styles["flex-row"]} ${styles["pro-list"]}`}
            >
              <Badge
                offset={[-5, 24]}
                style={{
                  backgroundColor:
                    status === "Offer Expired"
                      ? "#AFAFAF"
                      : status === "pending"
                        ? "#FFC043"
                        : status === "rejected"
                          ? "#AFAFAF"
                          : status === "cancelled"
                            ? "#FF4D4F"
                            : status === "error"
                              ? "#FF4D4F"
                              : "#20C997",
                  borderRadius: "50%",
                }}
                count={
                  status === "pending" ? (
                    <FeatherIcon
                      icon="more-horizontal"
                      color="#fff"
                      size={14}
                    />
                  ) : status === "Offer Expired" ||
                    status === "error" ||
                    status === "rejected" ||
                    status === "cancelled" ? (
                    <FeatherIcon icon="x" color="#fff" size={14} />
                  ) : (
                    <FeatherIcon icon="check" color="#fff" size={14} />
                  )
                }
              >
                <Avatar key={index} src={provider.image} />
              </Badge>
              <EllipsisLabel
                style={{
                  maxWidth: "150px",
                  marginLeft: "5px",
                  color:
                    status === "no_show_up" ? "#AFAFAF" : "#000000",
                }}
              >
                {provider.name ?? "Undefined"}
              </EllipsisLabel>
            </div>,
            status: <p
              style={{
                color: status === "no_show_up" ? "#AFAFAF" : "#000000",
              }}
            >
              {status === "no_show_up" ? "No-show" : capitalize(status)}
            </p>,
            rating: <Rate
              className={styles["stars"]}
              disabled
              defaultValue={provider.rating}
            />,
            gender: provider.gender ?? "Gender",
            actions: appointment?.status === "completed" && !able_to_adjust ? null
              : isEmpty(menuItems(provider, provider.unassigned ? "" : action, status)) ? null :
                <span style={{ textAlign: "end" }}>{[
                  ...(action === "offers"
                    ? ["assign_provider", "resend_offers"]
                    : []),
                ]?.some((val) =>
                  view === "admin"
                    ? (permissions?.shift?.includes(val) && appointment.is_staffing) || (permissions?.corporate?.includes(val) && appointment.is_corporate)
                    : (businessPermissions?.shift?.includes(val) &&
                      permissions?.shift?.includes(val) && appointment.is_staffing) || (businessPermissions?.corporate?.includes(val) && permissions?.corporate?.includes(val) && appointment.is_corporate)
                ) && (
                    <DropdownBase
                      className="actions-dropdown"
                      classNameC="actions-dropdown-content"
                      disabled={disabled}
                      action={["click"]}
                      content={
                        <Menu
                          items={menuItems(
                            provider,
                            provider.unassigned ? "" : action,
                            status
                          )}
                        />
                      }
                    >
                      <Link to="#">
                        <FeatherIcon
                          color="#ADB4D2"
                          icon="more-vertical"
                          size={18}
                          style={{
                            marginTop: "5px",
                          }}
                        />
                      </Link>
                    </DropdownBase>
                  )}</span>
          })
        })
      }
      return dataSource;
    }
    const columnsProviders = [{
      dataIndex: "name",
      key: "name",
      title: "Providers",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }, {
      dataIndex: "status",
      key: "status",
      title: "Status",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }, {
      dataIndex: "rating",
      key: "rating",
      title: "Rating",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }, {
      dataIndex: "gender",
      key: "gender",
      title: "Gender",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }, {
      dataIndex: "actions",
      key: "actions",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }]
    return (
      <Collapse
        bordered={false}
        defaultActiveKey={[customKey]}
        style={{ backgroundColor: "#fff" }}
      >
        <Panel header={title} key={customKey} style={{ marginLeft: "-8px" }}>
          <Form form={form} name="shift-form-2" className={styles["shift-form"]}>
            {data?.length ? <>
              <div className={`loc-table table-order table-responsive ${styles["providers-row"]}`}>
                <Table
                  dataSource={generateDataSourceProviders()}
                  columns={columnsProviders}
                  loading={isLoading}
                  // todo the table needs a separate loading state
                  pagination={{
                    current: page,
                    pageSize: 25,
                    showSizeChanger: false,
                    onChange: (pp) => {
                      if (totalOffers > 25) {
                        setPage(pp)
                        onOfferProsFetch(pp, searchValue)
                      }
                    },
                    total: totalOffers,
                    hideOnSinglePage: totalOffers <= 25 ? true : false
                  }}
                />
              </div>
            </> : null}
          </Form>

        </Panel>
      </Collapse>
    );
  };
  const WhosWorkingPanelReadOnly = ({ title, data, unassigned, customKey }) => {
    let columns = [{
      dataIndex: "nameReadOnly",
      key: "nameReadOnly",
      title: "Providers",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }, {
      dataIndex: "statusReadOnly",
      key: "statusReadOnly",
      title: "Status",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }, {
      dataIndex: "shiftLengthReadOnly",
      key: "shiftLengthReadOnly",
      title: "Appointment Length",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`,
    }, {
      dataIndex: "breakLengthReadOnly",
      key: "breakLengthReadOnly",
      title: "Break Length",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`,
    }, {
      dataIndex: "tipReadOnly",
      key: "tipReadOnly",
      title: "Tip",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }];
    let secondColumns = [{
      dataIndex: "requestedProIndexReadOnly",
      key: "requestedProIndexReadOnly",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }, {
      dataIndex: "unassignedProTipReadOnly",
      key: "unassignedProTipReadOnly",
      className: `${styles["rm-style"]} ${styles["small-padding-left"]}`
    }]

    let tempDataSource = [];
    let secondTempDataSource = [];
    if (!isEmpty(data)) {
      data?.map((provider, index) => {
        let status = provider?.status;
        let cart_product = appointment.cart_products.filter(
          (cp) => cp.cart_product_id === provider.cart_product_id
        )[0];

        tempDataSource.push({
          key: index,
          nameReadOnly: <div
            className={`${styles["flex-row"]} ${styles["pro-list"]}`}
          >
            <Badge
              offset={[-5, 24]}
              style={{
                backgroundColor:
                  status === "Offer Expired"
                    ? "#AFAFAF"
                    : status === "pending"
                      ? "#FFC043"
                      : status === "rejected"
                        ? "#AFAFAF"
                        : status === "cancelled"
                          ? "#FF4D4F"
                          : status === "error"
                            ? "#FF4D4F"
                            : "#20C997",
                borderRadius: "50%",
              }}
              count={
                status === "pending" ? (
                  <FeatherIcon
                    icon="more-horizontal"
                    color="#fff"
                    size={14}
                  />
                ) : status === "Offer Expired" ||
                  status === "error" ||
                  status === "rejected" ||
                  status === "cancelled" ? (
                  <FeatherIcon icon="x" color="#fff" size={14} />
                ) : (
                  <FeatherIcon icon="check" color="#fff" size={14} />
                )
              }
            >
              <Avatar key={index} src={provider.image} />
            </Badge>
            <EllipsisLabel
              style={{
                maxWidth: "150px",
                marginLeft: "5px",
                color:
                  status === "no_show_up" ? "#AFAFAF" : "#000000",
              }}
            >
              {provider.name ?? `Provider ${index + 1}`}
            </EllipsisLabel>
          </div>,
          statusReadOnly: <p
            style={{
              color: status === "no_show_up" ? "#AFAFAF" : "#000000",
            }}
          >
            {status === "no_show_up" ? "No-show" : capitalize(status)}
          </p>,
          shiftLengthReadOnly: status === "no_show_up" ? "" : `${get(provider, 'start_time.display', '')} - ${get(provider, 'end_time.display', '')}`,
          breakLengthReadOnly: status === "no_show_up" ? "" : `Break ${get(provider, "break_minutes", 0)} min`,
          tipReadOnly: <span style={{ color: "#545454" }}>Tip {get(cart_product, "tip_amount", 0) || 0}<span style={{ color: "#5F637A" }}>$</span></span>
        })
      })
    }
    if (!isEmpty(unassigned)) {
      unassigned?.map((provider, index) => {
        let cart_product = appointment.cart_products.filter(
          (cp) => cp.cart_product_id === provider.cart_product_id
        )[0];
        secondTempDataSource.push({
          key: index,
          requestedProIndexReadOnly: <div
            className={`${styles["flex-row"]} ${styles["pro-list"]}`}
          >
            <Avatar
              key={index}
              src={provider.image}
              style={{ backgroundColor: "#ADB4D2" }}
            />
            <p style={{ color: "#545454", marginLeft: "5px" }}>
              Requested Provider {index + 1} (Unassigned)
            </p>
          </div>,
          unassignedProTipReadOnly: <span style={{ color: "#545454" }}>Tip {get(cart_product, "tip_amount", 0) || 0}<span style={{ color: "#5F637A" }}>$</span></span>
        })
      })
    }
    return (
      <Collapse
        bordered={false}
        defaultActiveKey={[customKey]}
        style={{ backgroundColor: "#fff" }}
      >
        <Panel header={title} key={customKey} style={{ marginLeft: "-8px" }}>
          {isEmpty(data) ? null : <Form form={form} name="shift-form-3" className={styles["shift-form"]}>
            <Table
              dataSource={tempDataSource}
              columns={columns}
              pagination={false}
            />
          </Form>}
          {isEmpty(unassigned) ? null : <Table
            dataSource={secondTempDataSource}
            columns={secondColumns}
            pagination={false}
          />}
        </Panel>
      </Collapse>)
  }

  const SendToAllConfirmationModal = () => {
    return (confirmModal2.isOpen && (
      <Modal
        className="no-lines-modal"
        closable={false}
        centered
        open={confirmModal2.isOpen}
        title={<div className="color-dark text-center">Are you sure?</div>}
        footer={[
          {
            size: "large",
            className: "my-10",
            type: "default",
            outlined: true,
            style: {
              fontSize: "14px",
              lineHeight: "16px"
            },
            text: "No, Go Back",
            onClick: () => setConfirmModal2({ isOpen: false, isResend: false }),
          },
          {
            size: "large",
            type: "primary",
            text: `Yes, ${confirmModal2.isResend ? "Resend" : "Send"}  Offers to All Providers >`,
            style: {
              fontSize: "14px",
              lineHeight: "16px"
            },
            onClick: () => {
              setConfirmModal2({ isOpen: false, isResend: false });
              unWhiteListApt();
            },
          },
        ].map((button, index) => (
          <Button
            className={button.className}
            key={`shift-cw-confirm-modal-btn-${index}`}
            size={button.size}
            type={button.type}
            outlined={button.outlined}
            onClick={button.onClick}
            style={button.style}
          >
            {button.text}
          </Button>
        ))}
      ><div className="color-dark text-center">You're about to {confirmModal2.isResend ? "resend" : "send"} this offer to ALL Soothe Providers in your area.<br /><br />
          This action cannot be undone. If you only want to {confirmModal2.isResend ? "resend" : "send"} this offer to your Whitelisted Providers, please go back and select them manually.</div>
      </Modal>
    ))
  }
  return (
    <>
      <div className={styles["scrollbox"]}>
        {status === "pending" &&
          appointment.needs_attention &&
          !appointment.partially_filled && (
            <FlexRow>
              <FeatherIcon icon="alert-octagon" size={42} color="#C11500" />
              <p className={styles["needsAttention-warning"]}>
                This request exceeded the specified date and time and we are
                unable to find a Pro for this appointment. Please notify your
                client as soon as possible and either modify or cancel this
                appointment.
              </p>
            </FlexRow>
          )}
        <div className="view-modal-body-card"
          style={{ marginBottom: "22px" }}>
          <Card
            style={{
              backgroundColor:
                !appointment.partially_filled && appointment.needs_attention
                  ? "#FFEFED"
                  : "#F1F6FF",
              color:
                !appointment.partially_filled && appointment.needs_attention
                  ? "#C11500"
                  : "#272B41",
              marginBottom: "5px",
              border: "none",
            }}
            headless="true"
          >
            <FlexRow>
              <FeatherIcon
                icon="calendar"
                size={16}
                color={
                  !appointment.partially_filled &&
                    appointment.status === "pending" &&
                    appointment.needs_attention
                    ? "#C11500"
                    : "#9299B8"
                }
                className="mr-10"
              />
              <p>{moment(session_date).format("dddd, MMM DD")}</p>
            </FlexRow>
            <FlexRow>
              <FeatherIcon
                icon="clock"
                size={16}
                color={
                  !appointment.partially_filled &&
                    appointment.status === "pending" &&
                    appointment.needs_attention
                    ? "#C11500"
                    : "#9299B8"
                }
                className="mr-10"
              />
              <p>{`${time?.display} - ${cart_end_time?.display}`}</p>
            </FlexRow>
            {(appointment?.b2b_type !== "staffing" && appointment?.b2b_type !== "corporate") &&
              appointment?.address?.hotel_room_number !== "" &&
              appointment?.address?.hotel_room_number !== null && (
                <FlexRow>
                  <FeatherIcon
                    icon="hash"
                    size={16}
                    color={
                      !appointment.partially_filled &&
                        status === "pending" &&
                        appointment.needs_attention
                        ? "#C11500"
                        : "#9299B8"
                    }
                    className="mr-10"
                  />
                  <p>Room {appointment?.address?.hotel_room_number}</p>
                </FlexRow>
              )}
            <FlexRow>
              <FeatherIcon
                icon="map-pin"
                size={16}
                color={
                  !appointment.partially_filled &&
                    appointment.status === "pending" &&
                    appointment.needs_attention
                    ? "#C11500"
                    : "#9299B8"
                }
                className="mr-10"
              />
              <p>{location_name}</p>
            </FlexRow>

            <FlexRow>
              <FeatherIcon
                icon="list"
                size={16}
                color={
                  !appointment.partially_filled &&
                    appointment.status === "pending" &&
                    appointment.needs_attention
                    ? "#C11500"
                    : "#9299B8"
                }
                className="mr-10"
              />
              <p>{product_category}</p>
            </FlexRow>
            <FlexRow>
              <FeatherIcon
                icon="users"
                size={16}
                color={
                  !appointment.partially_filled &&
                    appointment.status === "pending" &&
                    appointment.needs_attention
                    ? "#C11500"
                    : "#9299B8"
                }
                className="mr-10"
              />
              <div>
                {assigned_providers_count} of {number_of_providers} Providers
                Confirmed
              </div>
            </FlexRow>
            <FlexRow>
              <FeatherIcon
                icon="user-check"
                size={16}
                color={
                  !appointment.partially_filled &&
                    appointment.status === "pending" &&
                    appointment.needs_attention
                    ? "#C11500"
                    : "#9299B8"
                }
                className="mr-10"
              />
              <p>
                {appointment.status === "cancelled" ? "Cancelled by" : "Booked by"}{" "}
                {(appointment.is_concierge || appointment.is_staffing || appointment.is_corporate) &&
                  appointment?.b2b_cart_details ? (
                  <span className={styles["booked-by"]}>
                    {appointment?.b2b_cart_details?.admin_name}
                  </span>
                ) : (
                  <span className={styles["booked-by"]}>
                    {appointment.booked_by}
                  </span>
                )}{" "}
                on {appointment.created_at}
              </p>
            </FlexRow>
            {(appointment.b2b_type === "staffing" || appointment.b2b_type === "corporate") &&
              status !== "cancelled" &&
              status !== "completed" && (
                <FlexRow style={{ marginTop: "5px" }}>
                  <FeatherIcon
                    icon="users"
                    size={16}
                    color={
                      !appointment.partially_filled &&
                        appointment.status === "pending" &&
                        appointment.needs_attention
                        ? "#C11500"
                        : "#9299B8"
                    }
                    className="mr-10"
                  />
                  <div>
                    <FlexRow>
                      Providers Needed{" "}
                      <span className={styles["nb-of-providers"]}>
                        {number_of_providers}
                      </span>{" "}
                      {status !== "cancelled" && status !== "completed" && (
                        <Restricted
                          both={view === "admin" ? false : true}
                          to="create_slot"
                          resource={b2b_type === "corporate" ? "corporate" : "shift"}
                          fallback={<></>}
                        >
                          <FeatherIcon
                            icon="plus"
                            size={12}
                            className={styles["plus-icon"]}
                            onClick={() =>
                              onUpdateShiftProviders({}, "create_slot")
                            }
                          />
                        </Restricted>
                      )}
                    </FlexRow>
                  </div>
                </FlexRow>
              )}
          </Card>
        </div>
        {!isEmpty(filteredProviders) && (b2b_type === "staffing" || b2b_type === "corporate") &&
          status !== "cancelled" &&
          status !== "completed" &&
          status !== "filled" && (
            <div
              className={styles["send-offer-container"]}
              style={{ marginBottom: "22px" }}
            >
              <Select
                className={styles["appointment-select"]}
                placeholder="Send offer to more Providers"
                suffixicon={<FeatherIcon icon="chevron-down" size="15px" />}
                mode="multiple"
                allowClear
                showArrow={false}
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                value={selectedProviders}
                onChange={(value) => setSelectedProviders(value)}
              >
                {map(filteredProviders, (provider, it) => {
                  let isTrainingNeeded =
                    get(provider, "is_training_needed");
                  let isNotTrainingNeeded =
                    !get(provider, "is_training_needed");
                  return (
                    <Select.Option
                      key={`list-${it}`}
                      disabled={
                        is_training ? isNotTrainingNeeded : isTrainingNeeded
                      }
                      value={provider.provider_id}
                      className={styles["providers-select"]}
                      style={{ padding: "2px" }}
                      label={provider.name}
                    >
                      <img
                        src={provider.image}
                        alt=""
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                          marginRight: "4px",
                        }}
                      />{" "}
                      <span> {provider.name}</span>
                      {!!get(provider, "is_training_needed") && (
                        <span
                          style={{
                            borderRadius: "20px",
                            backgroundColor: "#FFC043",
                            padding: "2px 6px",
                            marginRight: "10px",
                            color: "black",
                          }}
                        >
                          Training Needed
                        </span>
                      )}
                      {!!provider.is_new && (
                        <span
                          style={{
                            borderRadius: "4px",
                            backgroundColor: "#F58266",
                            padding: "2px 5px",
                            color: "white",
                          }}
                        >
                          New
                        </span>
                      )}
                    </Select.Option>
                  );
                })}
              </Select>
              <Restricted
                both={view === "admin" ? false : true}
                to="send_offers"
                resource={b2b_type === "corporate" ? "corporate" : "shift"}
                fallback={<></>}
              >
                <Button
                  size="default"
                  type="sootheButton"
                  onClick={() =>
                    onUpdateShiftProviders(
                      { provider_ids: selectedProviders },
                      "send_offers"
                    )
                  }
                  disabled={isEmpty(selectedProviders)}
                >
                  Send Offers
                </Button>
              </Restricted>
            </div>
          )}

        {!isEmpty(postShiftProviders) && (b2b_type === "staffing" || b2b_type === "corporate") &&
          status === "completed" && able_to_adjust ? (
          <Restricted
            both={false}
            to={`post_shift_create_slot_and_assign_provider_${b2b_type === "corporate" ? "corporate" : "staffing"}`}
            resource={b2b_type === "corporate" ? "corporate" : "shift"}
            fallback={<></>}
          >
            <div
              className={styles["send-offer-container"]}
              style={{ marginBottom: "22px" }}
            >
              <Select
                dropdownClassName={`custom-menu-style`}
                className={`${styles["appointment-select"]} post-shift-select pl-0`}
                placeholder="Add a Provider"
                suffixicon={<FeatherIcon icon="chevron-down" size="15px" />}
                mode="single"
                allowClear
                showArrow={false}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label.toLowerCase() ?? "").includes(
                    input.toLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                value={selectedPostShiftPro}
                onChange={(value) => {
                  setSelectedPostShiftPro(value)
                }}
              >
                <Select.OptGroup label="More Providers">
                  {map(postShiftProviders, (provider, it) => {
                    let isTrainingNeeded =
                      get(provider, "is_training_needed");
                    let isNotTrainingNeeded =
                      !get(provider, "is_training_needed");
                    return (
                      <Select.Option
                        key={`list-assign-${it}`}
                        disabled={
                          is_training ? isNotTrainingNeeded : isTrainingNeeded
                        }
                        value={provider.provider_id}
                        className={styles["providers-select"]}
                        style={{ padding: "2px" }}
                        label={provider.name}
                      >
                        <img
                          src={provider.image}
                          alt=""
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            marginRight: "4px",
                          }}
                        />{" "}
                        <span> {provider.name}</span>
                        {!!get(provider, "is_training_needed") && (
                          <span
                            style={{
                              borderRadius: "20px",
                              backgroundColor: "#FFC043",
                              padding: "2px 6px",
                              marginRight: "10px",
                              color: "black",
                            }}
                          >
                            Training Needed
                          </span>
                        )}
                        {!!provider.is_new && (
                          <span
                            style={{
                              borderRadius: "4px",
                              backgroundColor: "#F58266",
                              padding: "2px 5px",
                              color: "white",
                            }}
                          >
                            New
                          </span>
                        )}
                        <span className="hide-in-item" style={{ float: 'right' }}>{selectedPostShiftPro === provider.provider_id ? <RadioButtonFilled /> : <RadioButtonOutlined />}</span>
                      </Select.Option>
                    );
                  })}
                </Select.OptGroup>
                <Select.OptGroup label="Previously Rejected">
                </Select.OptGroup>
              </Select>
              <Button
                size="default"
                type="sootheButton"
                onClick={() =>
                  onAssignProInPostShift({ pro_id: selectedPostShiftPro })
                }
                disabled={!selectedPostShiftPro}
              >
                Assign Provider
              </Button>
            </div>
          </Restricted>
        ) : null}
        <div>
          <Restricted
            both={appointment?.status === "completed" ? false : view === "admin" ? false : true}
            to={appointment?.status === "completed" ?
              `post_shift_confirmed_providers_${b2b_type === "corporate" ? "corporate" : "staffing"}`
              : "confirmed_providers"}
            resource={b2b_type === "corporate" ? "corporate" : "shift"}
            fallback={<>
              <WhosWorkingPanelReadOnly
                title={
                  <p className={styles["section-title"]}>
                    {`Who's Working (${assigned_providers_count} of
                      ${number_of_providers})`}
                  </p>
                }
                data={providers_json}
                unassigned={unassigned_slots}
                customKey={3}
              />
              <Divider style={{ margin: "0px 0px 10px 0px" }} />
            </>
            }
          >
            <WhosWorkingPanel
              title={
                <p className={styles["section-title"]}>
                  {`Who's Working (${assigned_providers_count} of
                      ${number_of_providers})`}
                </p>
              }
              disabled={(!able_to_adjust && status === "completed") ||
                (status === "cancelled" ||
                  ((datePassed || timePassed) &&
                    status === "pending" &&
                    !partially_filled))
              }
              data={providers_json}
              action="confirmed"
              unassigned={unassigned_slots}
              customKey={1}
            />
            <Divider style={{ margin: "0px 0px 10px 0px" }} />
          </Restricted>
          {(appointment.b2b_type === "staffing" || b2b_type === "corporate") && (
            <Restricted
              both={view === "admin" ? false : true}
              to="offers_sent"
              resource={b2b_type === "corporate" ? "corporate" : "shift"}
              fallback={<></>}
            >
              {currentOffersJson?.length || searchValue ?
                <Input
                  style={{ marginBottom: "12px" }}
                  onChange={(e) => {
                    setSearchValue(e.target.value)
                  }}
                  value={searchValue}
                  placeholder="Search Providers"
                  className={styles["search-input"]}
                  suffix={<FeatherIcon size={16} icon="search" color="#9299B8" />}
                  disabled={isLoading}
                  allowClear
                  ref={inputRef}
                />
                : null}
              <TablePanels
                title={<div className="flex-spaced">
                  <p className={styles["section-title"]}>
                    {`Offers Sent (${totalOffers})`}
                  </p>
                  {b2b_type !== "corporate" ||
                    status === "cancelled" ||
                    status === "completed" ||
                    (
                      status === "filled" ||
                      (datePassed || timePassed)) ? null :
                    (isUndefined(white_listed) || isNull(white_listed) || white_listed) ? <Restricted to="send_offers" resource="corporate" both={view === "admin" ? false : true} fallback={<></>}>
                      <Button className="padding-5-10 " shape="round" type="orangeButton" size="sm"
                        onClick={() => {
                          setConfirmModal2({ isOpen: true, isResend: false });
                        }}>Send to All Providers</Button>
                    </Restricted> :
                      <Restricted to="resend_offers" resource="corporate" both={view === "admin" ? false : true} fallback={<></>}>
                        <Button className="padding-5-10 " shape="round" type="orangeButton" size="sm"
                          onClick={() => {
                            setConfirmModal2({ isOpen: true, isResend: true });
                          }}>Resend to All Providers</Button>
                      </Restricted>}
                </div>
                }
                disabled={
                  status === "cancelled" ||
                  status === "completed" ||
                  ((datePassed || timePassed) &&
                    status === "pending" &&
                    !partially_filled)
                }
                data={currentOffersJson}
                action="offers"
                customKey={2}
              />
            </Restricted>
          )}
        </div>
        <WarningModal isOpen={!isEmpty(warningModalState)} onExit={() => { setWarningModalState(null) }} onSubmit={() => {
          if (warningModalState.functn) {
            warningModalState.functn()
          }
        }}
        />
      </div>

      {slotUpdatePolicy.isCancelModalOpen && (
        <CancelAppointmentModal
          isOpen={slotUpdatePolicy.isCancelModalOpen}
          appointmentId={appointment_number}
          onExit={() => setSlotUpdatePolicy({
            copyType: "",
            isCancelModalOpen: false,
            submissionFunc: null,
          })}
          onCancel={() => {
            setIsReasonsToCancelModalOpen(true);
            setSlotUpdatePolicy({
              ...slotUpdatePolicy,
              isCancelModalOpen: false,
            })
          }}
          slotId={slotUpdatePolicy.slotId}
          copyType={slotUpdatePolicy.copyType}
        />
      )}
      {isReasonsToCancelModalOpen && (
        <ReasonsToCancelAppointmentModal
          loading={loading}
          setLoading={setLoading}
          isOpen={isReasonsToCancelModalOpen}
          appointmentId={appointment_number}
          businessId={appointment.business_id}
          onExit={() => {
            setIsReasonsToCancelModalOpen(false);
            setSlotUpdatePolicy({
              ...slotUpdatePolicy,
              copyType: "",
              isCancelModalOpen: false,
              submissionFunc: null,
              slotId: null,
            })
          }}
          selectedReasonForCancel={selectedReasonForCancel}
          setSelectedReasonForCancel={setSelectedReasonForCancel}
          setOtherReasonExplanation={setOtherReasonExplanation}
          otherReasonExplanation={otherReasonExplanation}
          slotId={slotUpdatePolicy.slotId}
          copyType={slotUpdatePolicy.copyType}
          handleSubmit={async (subPayload) => {
            await slotUpdatePolicy.submissionFunc(subPayload);
            setIsReasonsToCancelModalOpen(false);
            setSlotUpdatePolicy({
              ...slotUpdatePolicy,
              copyType: "",
              isCancelModalOpen: false,
              submissionFunc: null,
            })
          }}
          setAdditionalDetailsInParent={setAdditionalDetails}
        />
      )}
      {/* slot shortening not in scope for now */}
      {/* {slotUpdatePolicy.isFeeModalOpen && (
        <FeeModal
          isOpen={slotUpdatePolicy.isFeeModalOpen}
          title={slotUpdatePolicy.shorten ? "Are you sure you want to adjust this shift?" : "Are you sure you want to remove this slot?"}
          body={slotUpdatePolicy.shorten ?
            <div>
              <p>
                Adjusting this shift will put the shift in a pending state until the selected Providers accept the new changes.
              </p>
              <p>
                Shortening this shift by 50% or more less than 24 hours from shift start time will incur a $100 fee, charged to your next month’s invoice.
              </p>
              <p>
                <span style={{ fontWeight: 500 }}>You will be charged $100 for shortening a shift by 50% with a confirmed Provider.</span> Are you sure you want to remove this slot?
              </p>
            </div>
            : <div>
              <p>
                Rescheduling will put the shift in a pending state until the selected Providers accept the new changes.
              </p>
              <p>
                Since this shift is less than 24 hours away, rescheduling will incur a {parseInt(appointment.reschedule_fee).toFixed(2)} fee per confirmed Provider, charged to your next month's invoice.
              </p>
              <p>
                <span style={{ fontWeight: 500 }}>You will be charged {parseInt(appointment.reschedule_fee).toFixed(2) * 2} for rescheduling an appointment with 2 confirmed Providers.</span> Are you sure you want to reschedule this appointment?
              </p>
            </div>
          }
          submissionCopy={slotUpdatePolicy.shorten ? "Yes, adjust slot" : "Yes, remove slot"}
          onExit={() => {
            setSlotUpdatePolicy({
              isFeeModalOpen: false,
              shorten: false,
            })
          }}
          onSubmit={() => {
            if (slotUpdatePolicy.submissionFunc) {
              slotUpdatePolicy.submissionFunc()
            }
          }}
        />
      )} */}
      {confirmModal.isOpen && (
        <ConfirmModal
          title={`Are you sure you want to mark ${confirmModal.proName} as a no-show?`}
          text="This will adjust the total number of Providers and cost per shift. "
          buttons={[
            {
              className: "my-10",
              size: "small",
              type: "danger",
              outlined: true,
              text: "No, go back to Appointment Details",
              onClick: () => onExitConfirmModal(),
            },
            {
              size: "small",
              type: "primary",
              loading: confirmModal.isLoading,
              text: "Yes, mark as “no-show”",
              onClick: () => handleMarkAsANoShow(),
            },
          ]}
          isOpen={confirmModal.isOpen}
        />
      )}
      <SendToAllConfirmationModal />
    </>
  );
}

export default AppointmentOffers;
