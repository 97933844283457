import moment from "moment-timezone";
import { getMinutes } from "../../../../utilities/helpers";
import { ADDED_MARGIN_H, DEFAULT_END_HOUR, DEFAULT_START_HOUR } from "../../../../utilities/constants";

export const onSelectHourFrom = ({
    time,
    form,
    availableShiftTime,
    selectedDay,
    shiftHours,
    setShiftHours,
    isSootheMember,
    setLastDisabledHours
}) => {
    const dateFormat = "YYYY-MM-DD";
    const timeFormat = "hh:mm";

    const newTimeNow = isSootheMember ? moment().add(30, 'minutes') : moment().add(ADDED_MARGIN_H, 'hour');
    const firstAppointmentAvailableHour = newTimeNow.hour();
    const firstAppointmentAvailableMinutes = getMinutes(newTimeNow.minute());

    const firstAppointmentEnabledHour = moment(
        availableShiftTime.start,
        timeFormat
    ).hour();
    const firstAppointmentEnabledMinutes = moment(
        availableShiftTime.start,
        timeFormat
    ).minute();
    const lastAppointmentEnabledHour = moment(
        availableShiftTime.end,
        timeFormat
    ).hour();
    const lastAppointmentEnabledMinute = moment(
        availableShiftTime.end,
        timeFormat
    ).minute();

    let startTime;
    if (isSootheMember && selectedDay.isSame(moment(), "day")) {
        startTime = selectedDay.set({
            hours: time.hour() < firstAppointmentEnabledHour ? firstAppointmentEnabledHour : time.hour(),
            minutes: time.hour() === firstAppointmentEnabledHour && time.minute() < firstAppointmentEnabledMinutes ? firstAppointmentEnabledMinutes : time.minute(),
        });
        setShiftHours({
            shiftHours: { ...shiftHours, start: startTime },
        });

        form.setFieldsValue({
            "start-time": startTime,
        });
        onChangeHourFrom(startTime, setLastDisabledHours);
        return;
    }

    if (moment().format(dateFormat) === selectedDay.format(dateFormat)) {
        if (time.hour() <= firstAppointmentEnabledHour) {
            if (firstAppointmentAvailableHour < firstAppointmentEnabledHour) {
                startTime = time.set({
                    hours: firstAppointmentEnabledHour,
                    minutes:
                        time.minute() < firstAppointmentEnabledMinutes
                            ? firstAppointmentEnabledMinutes
                            : time.minute(),
                });

                setShiftHours({
                    shiftHours: { ...shiftHours, start: startTime },
                });

                form.setFieldsValue({
                    "start-time": startTime,
                });
                onChangeHourFrom(startTime, setLastDisabledHours);
                return;
            }
            if (firstAppointmentAvailableHour > firstAppointmentEnabledHour) {
                if (isSootheMember) {
                    startTime = time.set({
                        hours: firstAppointmentAvailableHour,
                        minutes: firstAppointmentAvailableMinutes,
                    });
                } else {
                    startTime = time.set({
                        hours:
                            moment().minutes() > 57
                                ? firstAppointmentAvailableHour + 1
                                : firstAppointmentAvailableHour,
                        minutes:
                            moment().minutes() > 57
                                ? 0
                                : getMinutes(firstAppointmentAvailableMinutes),
                    });
                }
                setShiftHours({
                    shiftHours: { ...shiftHours, start: startTime },
                });

                form.setFieldsValue({
                    "start-time": startTime,
                });
                onChangeHourFrom(startTime, setLastDisabledHours);
                return;
            }
        }
        if (time.hour() === firstAppointmentAvailableHour) {
            if (isSootheMember) {
                startTime = time.set({
                    hours: firstAppointmentAvailableHour,
                    minute:
                        time.minute() < firstAppointmentAvailableMinutes
                            ? firstAppointmentAvailableMinutes
                            : time.minute(),
                });
            } else {
                startTime = time.set({
                    hours:
                        moment().minutes() > 57
                            ? firstAppointmentAvailableHour + 1
                            : firstAppointmentAvailableHour,
                    minutes:
                        time.minute() < firstAppointmentAvailableMinutes
                            ? getMinutes(firstAppointmentAvailableMinutes)
                            : time.minute(),
                });
            }
            setShiftHours({
                shiftHours: { ...shiftHours, start: startTime },
            });

            form.setFieldsValue({
                "start-time": startTime,
            });
            onChangeHourFrom(startTime, setLastDisabledHours);
            return;
        }

        if (time.hour() >= lastAppointmentEnabledHour) {
            startTime = time.set({
                hours: lastAppointmentEnabledHour - 1,
                minutes: lastAppointmentEnabledMinute,
            });
            setShiftHours({
                shiftHours: { ...shiftHours, start: startTime },
            });

            form.setFieldsValue({
                "start-time": startTime,
            });
            onChangeHourFrom(startTime, setLastDisabledHours);
            return;
        }

        if (time.hour() < firstAppointmentAvailableHour) {
            if (isSootheMember) {
                startTime = time.set({
                    hours: firstAppointmentAvailableHour,
                    minute: firstAppointmentAvailableMinutes,
                });
            } else {
                startTime = time.set({
                    hours:
                        moment().minutes() > 57
                            ? firstAppointmentAvailableHour + 1
                            : firstAppointmentAvailableHour,
                    minutes:
                        moment().minutes() > 57
                            ? 0
                            : getMinutes(firstAppointmentAvailableMinutes),
                });
            }
            setShiftHours({
                shiftHours: { ...shiftHours, start: startTime },
            });

            form.setFieldsValue({
                "start-time": startTime,
            });
            onChangeHourFrom(startTime, setLastDisabledHours);
            return;
        }

        if (time.hour() === lastAppointmentEnabledHour) {
            startTime = time.set({
                hours: time.hour(),
            });

            setShiftHours({
                shiftHours: { ...shiftHours, start: startTime },
            });

            form.setFieldsValue({
                "start-time": startTime,
            });
            onChangeHourFrom(startTime, setLastDisabledHours);
            return;
        }
        if (time.hour() === firstAppointmentEnabledHour) {
            startTime = time.set({
                hours: firstAppointmentEnabledHour,
                minutes: time.minute(),
            });
            setShiftHours({
                shiftHours: { ...shiftHours, start: startTime },
            });

            form.setFieldsValue({
                "start-time": startTime,
            });
            onChangeHourFrom(startTime, setLastDisabledHours);
            return;
        }
    }

    if (time.hour() === moment(availableShiftTime.start, "HH:mm").hour()) {
        startTime = time.set({
            hours: moment(availableShiftTime.start, "HH:mm").hour(),
            minute:
                time.minute() < moment(availableShiftTime.start, "HH:mm").minute()
                    ? moment(availableShiftTime.start, "HH:mm").minute()
                    : time.minute(),
        });

        setShiftHours({
            shiftHours: { ...shiftHours, start: startTime },
        });

        form.setFieldsValue({
            "start-time": startTime,
        });

        onChangeHourFrom(startTime, setLastDisabledHours);
        return;
    }
    if (time.hour() < moment(availableShiftTime.start, "HH:mm").hour()) {
        startTime = time.set({
            hours: moment(availableShiftTime.start, "HH:mm").hour(),
            minute: moment(availableShiftTime.start, "HH:mm").minute(),
        });

        setShiftHours({
            shiftHours: { ...shiftHours, start: startTime },
        });

        form.setFieldsValue({
            "start-time": startTime,
        });

        onChangeHourFrom(startTime, setLastDisabledHours);
        return;
    }

    if (time.hour() >= moment(availableShiftTime.end, "HH:mm").hour()) {
        if (
            time.hour() >= moment(shiftHours.end, "HH:mm").hour() &&
            moment(shiftHours.end, "HH:mm").hour() !== 0
        ) {
            startTime = time.set({
                hours: moment(shiftHours.end, "HH:mm").hour() - 1,
                minute: 0,
            });
        } else {
            startTime = time.set({
                hours: moment(availableShiftTime.end, "HH:mm").hour() - 1,
                minute: 0,
            });
        }
        setShiftHours({
            shiftHours: { ...shiftHours, start: startTime },
        });

        form.setFieldsValue({
            "start-time": startTime,
        });
        onChangeHourFrom(startTime, setLastDisabledHours);
        return;
    }
    if (
        time.hour() >= moment(shiftHours.end, "HH:mm").hour() &&
        moment(shiftHours.end, "HH:mm").hour() !== 0
    ) {
        startTime = time.set({
            hours: moment(shiftHours.end, "HH:mm").hour() - 1,
            minute: 0,
        });

        setShiftHours({
            shiftHours: { ...shiftHours, start: startTime },
        });

        form.setFieldsValue({
            "start-time": startTime,
        });
        onChangeHourFrom(startTime, setLastDisabledHours);
        return;
    }

    setShiftHours({
        shiftHours: { ...shiftHours, start: time },
    });
    form.setFieldsValue({
        "start-time": time,
    });
    onChangeHourFrom(time, setLastDisabledHours);
    return;
};

export const onChangeHourFrom = (time, setLastDisabledHours) => {
    let disabled = [];
    for (let i = 0; i <= moment(time).hours(); i++) {
        disabled.push(i);
    }
    setLastDisabledHours(disabled);
};

export const onChangeHourTill = (time, setFirstDisabledHours) => {
    let disabled = [];
    for (let i = moment(time).hours(); i < DEFAULT_END_HOUR; i++) {
        disabled.push(i);
    }
    setFirstDisabledHours(disabled);
};

export const onSelectHourTill = ({
    time,
    form,
    availableShiftTime,
    shiftHours,
    setShiftHours,
    selectedDay,
    setFirstDisabledHours,
}) => {
    const dateFormat = "YYYY-MM-DD";
    const timeFormat = "hh:mm";
    const firstAppointmentAvailableHour = shiftHours.start
        ? shiftHours.start.hours()
        : moment().hour() + ADDED_MARGIN_H;

    const firstAppointmentEnabledHour = moment(
        availableShiftTime.start,
        timeFormat
    ).hour();
    const lastAppointmentEnabledHour = moment(
        availableShiftTime.end,
        timeFormat
    ).hour();
    const lastAppointmentEnabledMinutes = moment(
        availableShiftTime.end,
        timeFormat
    ).minute();
    if (moment().format(dateFormat) === selectedDay.format(dateFormat)) {
        if (time.hour() === firstAppointmentAvailableHour) {
            let endTime = time.set({
                hours: firstAppointmentAvailableHour + 1,
            });

            setShiftHours({
                shiftHours: { ...shiftHours, end: endTime },
            });
            form.setFieldsValue({
                "end-time": endTime,
            });

            onChangeHourTill(endTime, setFirstDisabledHours);
            return;
        }
        if (time.hour() < firstAppointmentEnabledHour) {
            let endTime = time.set({
                hours: firstAppointmentAvailableHour + 1,
                // minutes:
            });
            setShiftHours({
                shiftHours: { ...shiftHours, end: endTime },
            });
            form.setFieldsValue({
                "end-time": endTime,
            });
            onChangeHourTill(endTime, setFirstDisabledHours);
            return;
        }

        if (time.hour() > lastAppointmentEnabledHour) {
            let endTime = time.set({
                hours: lastAppointmentEnabledHour,
            });
            setShiftHours({
                shiftHours: { ...shiftHours, end: endTime },
            });
            form.setFieldsValue({
                "end-time": endTime,
            });
            onChangeHourTill(endTime, setFirstDisabledHours);
            return;
        }

        if (time.hour() < firstAppointmentAvailableHour) {
            let endTime = time.set({
                hours: firstAppointmentAvailableHour + 1,
            });
            setShiftHours({
                shiftHours: { ...shiftHours, end: endTime },
            });
            form.setFieldsValue({
                "end-time": endTime,
            });
            onChangeHourTill(endTime, setFirstDisabledHours);
            return;
        }
        if (time.hour() === lastAppointmentEnabledHour) {
            let endTime = time.set({
                hours: time.hour(),
                minute: lastAppointmentEnabledMinutes,
            });
            setShiftHours({
                shiftHours: { ...shiftHours, end: endTime },
            });
            form.setFieldsValue({
                "end-time": endTime,
            });
            onChangeHourTill(endTime, setFirstDisabledHours);
            return;
        }

        if (time.hour() === moment(shiftHours.start, "HH:mm").hour()) {
            let endTime = time.set({
                hours: moment(shiftHours.start, "HH:mm").hour() + 1,
            });
            // .format("HH:mm");
            setShiftHours({
                shiftHours: { ...shiftHours, end: endTime },
            });
            form.setFieldsValue({
                "end-time": endTime,
            });
            onChangeHourTill(endTime, setFirstDisabledHours);
        }
    }

    if (time.hour() < moment(availableShiftTime.start, "HH:mm").hour()) {
        let endTime = time.set({
            hours: moment(availableShiftTime.start, "HH:mm").hour() + 1,
            minute: moment(availableShiftTime.start, "HH:mm").minute(),
        });
        // .format("HH:mm");
        setShiftHours({
            shiftHours: { ...shiftHours, end: endTime },
        });
        form.setFieldsValue({
            "end-time": endTime,
        });
        onChangeHourTill(endTime, setFirstDisabledHours);
    }
    if (time.hour() <= moment(shiftHours.start, "HH:mm").hour()) {
        let endTime = time.set({
            hours: moment(shiftHours.start, "HH:mm").hour() + 1,
            minute: moment(shiftHours.start, "HH:mm").add(1, "hours").minute(),
        });
        // .format("HH:mm");
        setShiftHours({
            shiftHours: { ...shiftHours, end: endTime },
        });
        form.setFieldsValue({
            "end-time": endTime,
        });
        onChangeHourTill(endTime, setFirstDisabledHours);
    }
    if (time.hour() >= moment(availableShiftTime.end, "HH:mm").hour()) {
        let endTime = time.set({
            hours: moment(availableShiftTime.end, "HH:mm").hour(),
            minute: time.minute() <= moment(availableShiftTime.end, "HH:mm").minute() ? time.minute() : moment(availableShiftTime.end, "HH:mm").minute(),
        });
        // .format("HH:mm");
        setShiftHours({
            shiftHours: { ...shiftHours, end: endTime },
        });
        form.setFieldsValue({
            "end-time": endTime,
        });
        onChangeHourTill(endTime, setFirstDisabledHours);
    }

    setShiftHours({
        shiftHours: { ...shiftHours, end: time },
    });
    form.setFieldsValue({
        "end-time": time,
    });
    onChangeHourTill(time, setFirstDisabledHours);
    // time.format("HH:mm");
};

export const fromDisabledTime = ({
    availableShiftTime,
    firstDisabledHours,
    fixedDisabledHours,
    endShiftTime,
    selectedDay,
    isSootheMember,
    hasPostShiftPermission,
}) => {
    const timeFormat = "hh:mm";
    const dateFormat = "YYYY-MM-DD";
    const firstAppointmentEnabledHour = moment(
        availableShiftTime?.start,
        timeFormat
    ).hour();
    const lastAppointmentEnabledHour = moment(
        availableShiftTime?.end,
        timeFormat
    ).hour();
    return {
        disabledHours: () => {
            let disabled = [...firstDisabledHours];

            for (
                let i = moment(availableShiftTime.end, "hh:mm").hours();
                i <= DEFAULT_END_HOUR;
                i++
            ) {
                disabled.push(i);
            }
            for (
                let i = DEFAULT_START_HOUR;
                i < moment(availableShiftTime.start, "hh:mm").hours();
                i++
            ) {
                disabled.push(i);
            }
            if (hasPostShiftPermission) {
                return fixedDisabledHours.concat(disabled)
            }

            for (
                let i = endShiftTime.hours();
                i <= moment(availableShiftTime.end, "hh:mm").hours();
                i++
            ) {
                if (endShiftTime.hours() !== 0) {
                    disabled.push(i);
                }
            }

            if (moment().format(dateFormat) === selectedDay.format(dateFormat)) {
                for (
                    let i = firstAppointmentEnabledHour;
                    i <= lastAppointmentEnabledHour;
                    i++
                ) {
                    if (isSootheMember) {
                        if (i < moment().hours()) {
                            disabled.push(i);
                        }
                        if (i === moment().hours() && moment().minutes() > 5) {
                            disabled.push(i);
                        }
                    } else {
                        if (i < moment().hours() + ADDED_MARGIN_H) {
                            disabled.push(i);
                        }
                        if (i === moment().hours() + ADDED_MARGIN_H && moment().minutes() > 57) {
                            disabled.push(i);
                        }
                    }
                }
            }

            return fixedDisabledHours.concat(disabled);
        },
        disabledMinutes: (selectedHour) => {
            const timeFormat = "hh:mm";
            const dateFormat = "YYYY-MM-DD";
            const availbleTimeRange = availableShiftTime;
            const firstAppointmentEnabledTime = moment(
                availbleTimeRange?.start,
                timeFormat
            );
            const lastAppointmentEnabledTime = moment(
                availbleTimeRange?.end,
                timeFormat
            );
            if (!availbleTimeRange) return;
            let minutes = [];
            if (selectedHour < 0) {
                for (let i = 0; i < 60; i++) {
                    minutes.push(i);
                }
            } else if (selectedHour === firstAppointmentEnabledTime.hour()) {
                for (let i = firstAppointmentEnabledTime.minute() - 1; i >= 0; i--) {
                    minutes.push(i);
                }
            } else if (selectedHour === lastAppointmentEnabledTime.hour()) {
                for (let i = lastAppointmentEnabledTime.minute() + 1; i <= 60; i++) {
                    minutes.push(i);
                }
            }

            if (moment().format(dateFormat) === selectedDay.format(dateFormat)) {
                if (isSootheMember) {
                    if (selectedHour === moment().hour()) {
                        for (let i = 0; i < moment().minute() + 30; i++) {
                            if (i <= 30) {
                                minutes.push(i);
                            }
                        }
                    } else if (selectedHour - 1 === moment().hour()) {
                        for (let i = 0; i < moment().minute() - 30; i++) {
                            if (i % 5 === 0) {
                                minutes.push(i);
                            }
                        }
                    }
                } else {
                    if (selectedHour === moment().hour() + ADDED_MARGIN_H) {
                        for (let i = 0; i < moment().minute(); i++) {
                            minutes.push(i);
                        }
                    }
                }
            }

            return minutes;
        },
    };
};

export const lastDisabledTime = ({
    availableShiftTime,
    lastDisabledHours,
    fixedDisabledHours,
    startShiftTime,
    selectedDay,
    isSootheMember,
}) => {
    return {
        disabledHours: () => {
            const timeFormat = "hh:mm";
            const dateFormat = "YYYY-MM-DD";

            const firstAppointmentEnabledHour = moment(
                availableShiftTime?.start,
                timeFormat
            ).hour();
            const lastAppointmentEnabledHour = moment(
                availableShiftTime?.end,
                timeFormat
            ).hour();
            let disabled = [...lastDisabledHours];

            for (
                let i = DEFAULT_START_HOUR;
                i <= moment(availableShiftTime.start, "hh:mm").hours();
                i++
            ) {
                disabled.push(i);
            }

            for (
                let i = moment(availableShiftTime.end, "hh:mm").hours() + 1;
                i <= DEFAULT_END_HOUR;
                i++
            ) {
                disabled.push(i);
            }

            for (
                let i = moment(availableShiftTime.start, "hh:mm").hours() + 1;
                i <= startShiftTime.hours();
                i++
            ) {
                disabled.push(i);
            }
            if (moment().format(dateFormat) === selectedDay.format(dateFormat)) {
                if (!isSootheMember)
                    for (
                        let i = firstAppointmentEnabledHour;
                        i <= lastAppointmentEnabledHour;
                        i++
                    ) {
                        if (i < moment().hours() + 3) {
                            disabled.push(i);
                        }
                    }
            }
            return fixedDisabledHours.concat(disabled);
        },
        disabledMinutes: (selectedHour) => {
            const timeFormat = "hh:mm";
            const dateFormat = "YYYY-MM-DD";
            const availbleTimeRange = availableShiftTime;
            const firstAppointmentEnabledTime = moment(
                availbleTimeRange?.start,
                timeFormat
            );
            const lastAppointmentEnabledTime = moment(
                availbleTimeRange?.end,
                timeFormat
            );
            if (!availbleTimeRange) return;
            let minutes = [];

            if (selectedHour < 0) {
                for (let i = 0; i < 60; i++) {
                    minutes.push(i);
                }
            } else if (selectedHour === firstAppointmentEnabledTime.hour()) {
                for (let i = firstAppointmentEnabledTime.minute() - 1; i >= 0; i--) {
                    minutes.push(i);
                }
            } else if (selectedHour === lastAppointmentEnabledTime.hour()) {
                for (let i = lastAppointmentEnabledTime.minute() + 1; i <= 60; i++) {
                    minutes.push(i);
                }
            }
            if (moment().format(dateFormat) === selectedDay) {
                if (selectedHour === moment().hour() + ADDED_MARGIN_H) {
                    for (let i = 0; i < moment().minute(); i++) {
                        minutes.push(i);
                    }
                }
            }

            return minutes;
        },
    };
};

export const onSelectStartTime = ({
    time,
    setFormData,
    availableShiftTime,
    selectedDay,
    form,
    shiftHours,
    isSootheMember,
}) => {
    const dateFormat = "YYYY-MM-DD";
    const timeFormat = "hh:mm";

    const newTimeNow = isSootheMember ? moment().add(30, 'minutes') : moment().add(ADDED_MARGIN_H, 'hour');
    const firstAppointmentAvailableHour = newTimeNow.hour();
    const firstAppointmentAvailableMinutes = getMinutes(newTimeNow.minute());

    const firstAppointmentEnabledHour = moment(
        availableShiftTime.start,
        timeFormat
    ).hour();
    const firstAppointmentEnabledMinutes = moment(
        availableShiftTime.start,
        timeFormat
    ).minute();

    const lastAppointmentEnabledHour = moment(
        availableShiftTime.end,
        timeFormat
    ).hour();
    const lastAppointmentEnabledMinutes = moment(
        availableShiftTime.end,
        timeFormat
    ).minute();

    if (moment().format(dateFormat) === selectedDay.format(dateFormat)) {
        if (time.hour() <= firstAppointmentEnabledHour) {
            if (firstAppointmentAvailableHour < firstAppointmentEnabledHour) {
                let startTime = time.set({
                    hours: firstAppointmentEnabledHour,
                    minutes:
                        time.minute() < firstAppointmentEnabledMinutes
                            ? firstAppointmentEnabledMinutes
                            : time.minute(),
                });

                let endTime = moment(startTime, "hh:mm").add(
                    moment.duration(shiftHours.diff, "hours")
                );

                setFormData({
                    shiftHours: {
                        ...shiftHours,
                        start: startTime.format("HH:mm"),
                        end: endTime.format("HH:mm"),
                    },
                });

                form.setFieldsValue({
                    "start-time": startTime,
                    "end-time": endTime,
                });

                return;
            }
            if (firstAppointmentAvailableHour > firstAppointmentEnabledHour) {
                if (isSootheMember) {
                    let startTime = time.set({
                        hours: firstAppointmentAvailableHour,
                        minutes: firstAppointmentAvailableMinutes,
                    });

                    let endTime = moment(startTime, "hh:mm").add(
                        moment.duration(shiftHours.diff, "hours")
                    );

                    setFormData({
                        shiftHours: {
                            ...shiftHours,
                            start: startTime.format("HH:mm"),
                            end: endTime.format("HH:mm"),
                        },
                    });

                    form.setFieldsValue({
                        "start-time": startTime,
                        "end-time": endTime,
                    });
                    return;
                } else {
                    let startTime = time.set({
                        hours:
                            moment().minutes() > 57
                                ? firstAppointmentAvailableHour + 1
                                : firstAppointmentAvailableHour,
                        minutes:
                            moment().minutes() > 57
                                ? 0
                                : getMinutes(firstAppointmentAvailableMinutes),
                    });

                    let endTime = moment(startTime, "hh:mm").add(
                        moment.duration(shiftHours.diff, "hours")
                    );

                    setFormData({
                        shiftHours: {
                            ...shiftHours,
                            start: startTime.format("HH:mm"),
                            end: endTime.format("HH:mm"),
                        },
                    });

                    form.setFieldsValue({
                        "start-time": startTime,
                        "end-time": endTime,
                    });
                }
                return;
            }
        }

        if (time.hour() === firstAppointmentAvailableHour) {
            if (isSootheMember) {
                let startTime = time.set({
                    hours: firstAppointmentAvailableHour,
                    minute:
                        time.minute() < firstAppointmentAvailableMinutes
                            ? firstAppointmentAvailableMinutes
                            : time.minute(),
                });

                let endTime = moment(startTime, "hh:mm").add(
                    moment.duration(shiftHours.diff, "hours")
                );
                setFormData({
                    shiftHours: {
                        ...shiftHours,
                        start: startTime.format("HH:mm"),
                        end: endTime.format("HH:mm"),
                    },
                });

                form.setFieldsValue({
                    "start-time": startTime,
                    "end-time": endTime,
                });
            } else {
                let startTime = time.set({
                    hours:
                        moment().minutes() > 57
                            ? firstAppointmentAvailableHour + 1
                            : firstAppointmentAvailableHour,
                    minutes:
                        time.minute() < firstAppointmentAvailableMinutes
                            ? getMinutes(firstAppointmentAvailableMinutes)
                            : time.minute(),
                });

                let endTime = moment(startTime, "hh:mm").add(
                    moment.duration(shiftHours.diff, "hours")
                );

                setFormData({
                    shiftHours: {
                        ...shiftHours,
                        start: startTime.format("HH:mm"),
                        end: endTime.format("HH:mm"),
                    },
                });

                form.setFieldsValue({
                    "start-time": startTime,
                    "end-time": endTime,
                });
            }
            return;
        }

        if (time.hour() >= lastAppointmentEnabledHour) {
            let startTime = time.set({
                hours: lastAppointmentEnabledHour - 1,
                minutes: lastAppointmentEnabledMinutes,
            });

            let endTime = moment(startTime, "hh:mm").add(
                moment.duration(shiftHours.diff, "hours")
            );

            setFormData({
                shiftHours: {
                    ...shiftHours,
                    start: startTime.format("HH:mm"),
                    end: endTime.format("HH:mm"),
                },
            });

            form.setFieldsValue({
                "start-time": startTime,
                "end-time": endTime,
            });

            return;
        }

        if (time.hour() < firstAppointmentAvailableHour) {
            if (isSootheMember) {
                let startTime = time.set({
                    hours: firstAppointmentAvailableHour,
                    minute: firstAppointmentAvailableMinutes,
                });

                let endTime = moment(startTime, "hh:mm").add(
                    moment.duration(shiftHours.diff, "hours")
                );

                setFormData({
                    shiftHours: {
                        ...shiftHours,
                        start: startTime.format("HH:mm"),
                        end: endTime.format("HH:mm"),
                    },
                });

                form.setFieldsValue({
                    "start-time": startTime,
                    "end-time": endTime,
                });
            } else {
                let startTime = time.set({
                    hours:
                        moment().minutes() > 57
                            ? firstAppointmentAvailableHour + 1
                            : firstAppointmentAvailableHour,
                    minutes:
                        moment().minutes() > 57
                            ? 0
                            : getMinutes(firstAppointmentAvailableMinutes),
                });

                let endTime = moment(startTime, "hh:mm").add(
                    moment.duration(shiftHours.diff, "hours")
                );

                setFormData({
                    shiftHours: {
                        ...shiftHours,
                        start: startTime.format("HH:mm"),
                        end: endTime.format("HH:mm"),
                    },
                });

                form.setFieldsValue({
                    "start-time": startTime,
                    "end-time": endTime,
                });
            }
            return;
        }

        // i'm here
        if (time.hour() === lastAppointmentEnabledHour) {
            let startTime = time.set({
                hours: time.hour(),
            });

            let endTime = moment(startTime, "hh:mm").add(
                moment.duration(shiftHours.diff, "hours")
            );

            setFormData({
                shiftHours: {
                    ...shiftHours,
                    start: startTime.format("HH:mm"),
                    end: endTime.format("HH:mm"),
                },
            });

            form.setFieldsValue({
                "start-time": startTime,
                "end-time": endTime,
            });
        }

        if (time.hour() === firstAppointmentEnabledHour) {
            let startTime = time.set({
                hours: firstAppointmentEnabledHour,
                minutes: time.minute(),
            });

            let endTime = moment(startTime, "hh:mm").add(
                moment.duration(shiftHours.diff, "hours")
            );

            setFormData({
                shiftHours: {
                    ...shiftHours,
                    start: startTime.format("HH:mm"),
                    end: endTime.format("HH:mm"),
                },
            });

            form.setFieldsValue({
                "start-time": startTime,
                "end-time": endTime,
            });

            return;
        }
    }

    if (time.hour() === moment(availableShiftTime.start, "HH:mm").hour()) {
        let startTime = time.set({
            hours: moment(availableShiftTime.start, "HH:mm").hour(),
            minute:
                time.minute() < moment(availableShiftTime.start, "HH:mm").minute()
                    ? moment(availableShiftTime.start, "HH:mm").minute()
                    : time.minute(),
        });

        let endTime = moment(startTime, "hh:mm").add(
            moment.duration(shiftHours.diff, "hours")
        );

        setFormData({
            shiftHours: {
                ...shiftHours,
                start: startTime.format("HH:mm"),
                end: endTime.format("HH:mm"),
            },
        });

        form.setFieldsValue({
            "start-time": startTime,
            "end-time": endTime,
        });
        // return time
        //   .set({
        //     hours: moment(availableShiftTime.start, "HH:mm").hour(),
        //     minute:
        //       time.minute() < moment(availableShiftTime.start, "HH:mm").minute()
        //         ? moment(availableShiftTime.start, "HH:mm").minute()
        //         : time.minute(),
        //   })
        //   .format("HH:mm");

        return;
    }

    if (time.hour() < moment(availableShiftTime.start, "HH:mm").hour()) {
        let startTime = time.set({
            hours: moment(availableShiftTime.start, "HH:mm").hour(),
            minute: moment(availableShiftTime.start, "HH:mm").minute(),
        });

        let endTime = moment(startTime, "hh:mm").add(
            moment.duration(shiftHours.diff, "hours")
        );

        setFormData({
            shiftHours: {
                ...shiftHours,
                start: startTime.format("HH:mm"),
                end: endTime.format("HH:mm"),
            },
        });

        form.setFieldsValue({
            "start-time": startTime,
            "end-time": endTime,
        });

        // return time
        //   .set({
        //     hours: moment(availableShiftTime.start, "HH:mm").hour(),
        //     minute: moment(availableShiftTime.start, "HH:mm").minute(),
        //   })
        //   .format("HH:mm");
    }

    if (time.hour() >= moment(availableShiftTime.end, "HH:mm").hour()) {
        // if (
        //   time.hour() >= moment(shiftHours.end, "HH:mm").hour() &&
        //   moment(shiftHours.end, "HH:mm").hour() !== 0
        // ) {
        //   return time
        //     .set({
        //       hours: moment(shiftHours.end, "HH:mm").hour() - 1,
        //       minute: 0,
        //     })
        //     .format("HH:mm");
        // }

        let startTime = time.set({
            hours: moment(availableShiftTime.end, "HH:mm").hour() - 1,
            minute: 0,
        });

        let endTime = moment(startTime, "hh:mm").add(
            moment.duration(shiftHours.diff, "hours")
        );

        setFormData({
            shiftHours: {
                ...shiftHours,
                start: startTime.format("HH:mm"),
                end: endTime.format("HH:mm"),
            },
        });

        form.setFieldsValue({
            "start-time": startTime,
            "end-time": endTime,
        });
        return;
    }

    let startTime = time;

    let endTime = moment(startTime, "hh:mm").add(
        moment.duration(shiftHours.diff, "hours")
    );
    setFormData({
        shiftHours: {
            ...shiftHours,
            start: startTime.format("HH:mm"),
            end: endTime.format("HH:mm"),
        },
    });

    form.setFieldsValue({
        "start-time": startTime,
        "end-time": endTime,
    });
    return;
};

export const startDisabledTime = ({
    availableShiftTime,
    endShiftTime,
    firstDisabledHours,
    fixedDisabledHours,
    shiftHours,
    selectedDay,
    isSootheMember,
}) => {
    const timeFormat = "hh:mm";
    const dateFormat = "YYYY-MM-DD";
    const firstAppointmentEnabledHour = moment(
        availableShiftTime?.start,
        timeFormat
    ).hour();
    const lastAppointmentEnabledHour = moment(
        availableShiftTime?.end,
        timeFormat
    ).hour();
    return {
        disabledHours: () => {
            let disabled = [...firstDisabledHours];
            // for (let i = 0; i < shiftHours.diff; i++) disabled.push(moment(availableShiftTime.end, "hh:mm").hours() - i);
            for (
                let i = moment(availableShiftTime.end, "hh:mm").hours();
                i <= DEFAULT_END_HOUR;
                i++
            ) {
                disabled.push(i);
            }
            for (
                let i = DEFAULT_START_HOUR;
                i < moment(availableShiftTime.start, "hh:mm").hours();
                i++
            ) {
                disabled.push(i);
            }

            for (
                let i = endShiftTime.hours();
                i <= moment(availableShiftTime.end, "hh:mm").hours();
                i++
            ) {
                if (endShiftTime.hours() !== 0) {
                    disabled.push(i);
                }
            }
            if (moment().format(dateFormat) === selectedDay?.format(dateFormat)) {
                for (
                    let i = firstAppointmentEnabledHour;
                    i <= lastAppointmentEnabledHour;
                    i++
                ) {
                    if (isSootheMember) {
                        if (i < moment().hours()) {
                            disabled.push(i);
                        }
                        if (i === moment().hours() && moment().minutes() > 5) {
                            disabled.push(i);
                        }
                    } else {
                        if (i < moment().hours() + ADDED_MARGIN_H) {
                            disabled.push(i);
                        }
                        if (i === moment().hours() + ADDED_MARGIN_H && moment().minutes() > 57) {
                            disabled.push(i);
                        }
                    }
                }
            }
            return fixedDisabledHours.concat(disabled);
        },
        disabledMinutes: (selectedHour) => {
            const timeFormat = "hh:mm";
            const dateFormat = "YYYY-MM-DD";
            const availbleTimeRange = availableShiftTime;
            const firstAppointmentEnabledTime = moment(
                availbleTimeRange?.start,
                timeFormat
            );
            const lastAppointmentEnabledTime = moment(
                availbleTimeRange?.end,
                timeFormat
            );
            if (!availbleTimeRange) return;
            let minutes = [];

            if (selectedHour < 0) {
                for (let i = 0; i < 60; i++) {
                    minutes.push(i);
                }
            } else if (selectedHour === firstAppointmentEnabledTime.hour()) {
                for (let i = firstAppointmentEnabledTime.minute() - 1; i >= 0; i--) {
                    minutes.push(i);
                }
            } else if (selectedHour === lastAppointmentEnabledTime.hour()) {
                for (let i = lastAppointmentEnabledTime.minute() + 1; i <= 60; i++) {
                    minutes.push(i);
                }
            }
            if (moment().format(dateFormat) === selectedDay.format(dateFormat)) {
                if (isSootheMember) {
                    if (selectedHour === moment().hour()) {
                        for (let i = 0; i < moment().minute() + 30; i++) {
                            if (i <= 30) {
                                minutes.push(i);
                            }
                        }
                    } else if (selectedHour - 1 === moment().hour()) {
                        for (let i = 0; i < moment().minute() - 30; i++) {
                            if (i % 15 === 0) {
                                minutes.push(i);
                            }
                        }
                    }
                } else {
                    if (selectedHour === moment().hour() + ADDED_MARGIN_H) {
                        for (let i = 0; i < moment().minute(); i++) {
                            minutes.push(i);
                        }
                    }
                }
            }
            return minutes;
        },
    };
};

export const daysOfWeek = [
    { className: "day-tap", label: "Sat", value: 0 },
    { className: "day-tap", label: "Sun", value: 1 },
    { className: "day-tap", label: "Mon", value: 2 },
    { className: "day-tap", label: "Tue", value: 3 },
    { className: "day-tap", label: "Wed", value: 4 },
    { className: "day-tap", label: "Thu", value: 5 },
    { className: "day-tap", label: "Fri", value: 6 },
];
