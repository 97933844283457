import React from "react";
import PropTypes, { object } from "prop-types";
// import { ModalStyled } from "./styled";
import { Button } from "../buttons/buttons";
import Modal from "antd/es/modal";
import "antd/es/modal/style";
import { omit } from "lodash";

const ModalStyled = (props) => {
  const {
    onCancel,
    className,
    onOk,
    visible,
    title,
    type,
    color,
    footer,
    width,
    children,
    centered,
    closable,
    wrapClassName,
    subtitle,
  } = props;

  return (
    <Modal
      title={subtitle ?
        <div>
          <div>{title}</div>
          <div className="subtitle">{subtitle}</div>
        </div>
        : title}
      closable={closable}
      open={visible}
      mask
      onOk={onOk}
      onCancel={onCancel}
      type={color ? type : false}
      width={width}
      wrapClassName={wrapClassName}
      className={`${className} colored-modal`}
      centered={centered}
      {...omit(props, 'visible')}
      footer={
        footer || footer === null
          ? footer
          : [
            <Button type="secondary" key="back" onClick={onCancel}>
              Cancel
            </Button>,
            <Button type={type} key="submit" onClick={onOk}>
              Save Change
            </Button>,
          ]
      }
    >
      {children}
    </Modal>
  );
};

Modal.defaultProps = {
  width: 620,
  className: "atbd-modal",
};

Modal.propTypes = {
  onCancel: PropTypes.func,
  onOk: PropTypes.func,
  open: PropTypes.bool,
  title: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  footer: PropTypes.arrayOf(object),
  width: PropTypes.number,
  color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.node,
  ]),
};

export { ModalStyled as Modal };
