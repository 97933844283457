import React from "react";
import Radio from "antd/es/radio";
import Form from "antd/es/form";
import "antd/es/form/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";
import { Modal } from "../../atoms/modals/antd-modals";
import { Button } from "../../atoms/buttons/buttons";
import DataStateHandler from "../../../utilities/DataStateHandler";
import useGetAppointmentsDetails from "../../../utilities/hooks/useGetAppointmentDetails";
import useGetCancellationReasons from "../../../utilities/hooks/useGetCancellationReasons";
import styles from "./style.module.scss";
import { StyledInput } from "../../atoms";
import TextArea from "antd/lib/input/TextArea";

function ReasonsToRescheduleAppointmentModal({
  isOpen,
  onExit,
  selectedReasonForReschedule,
  setSelectedReasonForReschedule,
  loading,
  appointmentId,
  businessId,
  setOtherReasonExplanation,
  otherReasonExplanation,
  handleSubmit,
}) {
  const [additionalDetails, setAdditionalDetails] = React.useState("");
  const {
    data: selectedAppointment,
    isLoading,
    refetch,
  } = useGetAppointmentsDetails({
    appointmentId,
  });

  const {
    data: rescheduleReasons,
    isLoading: isRescheduleReasonsLoading,
    isError: isRescheduleError,
    error: cancellationError,
  } = useGetCancellationReasons(businessId);
  // TODO call reschedule reasons instead

  const [form] = Form.useForm();
  const validateFormThenSubmit = () => {
    form.validateFields().then((values) => {
      let subData = {
        cancellation_index: selectedReasonForReschedule?.index,
        custom_cancellation_reason: selectedReasonForReschedule === "other" ? otherReasonExplanation : null,
        additional_details: values["additional-details"],
        client_cancellation_reason_id: selectedReasonForReschedule?.id,
      };
      handleSubmit(subData);
    });
  };

  return (
    <>
      <Modal
        wrapClassName={styles["modal-container"]}
        title={
          !isRescheduleReasonsLoading &&
          "Please share your reason for rescheduling this shift."
        }
        visible={isOpen}
        footer={null}
        onCancel={onExit}
        centered
      >
        <DataStateHandler
          style={{ height: "40vh" }}
          isLoading={isLoading || isRescheduleReasonsLoading}
          loadingComponent={<Skeleton active paragraph={{ rows: 8 }} />}
          isError={isRescheduleError}
          error={cancellationError}
          data={rescheduleReasons?.length}
        >
          {rescheduleReasons && (
            <div className="view-modal-body">
              <Form
                form={form}
                name="reasons"
                onFinish={() => validateFormThenSubmit()}
              >
                <Form.Item
                  name="reason"
                  rules={[
                    {
                      required: true,
                      message: "Please select a reason",
                    },
                  ]}
                >
                  <Radio.Group>
                    <div className="ant-radio-btn-vertical">
                      {rescheduleReasons.map((rescheduleReason, index) => (
                        <Radio
                          key={rescheduleReason.id}
                          disabled={false}
                          value={rescheduleReason.id}
                          onChange={(e) => {
                            setSelectedReasonForReschedule({
                              id: e.target.value,
                              index,
                            })
                            setOtherReasonExplanation("");
                          }}
                        >
                          {rescheduleReason.reason}
                        </Radio>
                      ))}
                    </div>
                    <div className="ant-radio-btn_input">
                      <Radio
                        key={"other"}
                        disabled={false}
                        value={"other"}
                        onChange={(e) => setSelectedReasonForReschedule(e.target.value)}
                      >
                        Other:
                      </Radio>

                      <StyledInput
                        placeholder="Enter other reason"
                        value={otherReasonExplanation}
                        onChange={(e) => setOtherReasonExplanation(e.target.value)}
                        disabled={selectedReasonForReschedule !== "other"}
                        required={selectedReasonForReschedule === "other"}
                      />
                    </div>
                  </Radio.Group>
                </Form.Item>
                <div style={{
                  fontSize: "14px",
                  lineHeight: "20px",
                  fontWeight: "400",
                  marginBottom: "14px",
                }}>Additional Details*</div>
                <Form.Item
                  name="additional-details"
                  rules={[
                    {
                      required: true,
                      message: "Please add additional details",
                    },
                  ]}>
                  <TextArea
                    value={additionalDetails}
                    onChange={(e) => setAdditionalDetails(e.target.value)}
                    rows={4}
                    style={{ backgroundColor: "#FFFFFF", borderRadius: "4px" }}
                    maxLength={255}
                    showCount>
                  </TextArea>
                </Form.Item>
                <footer>
                  <Button
                    key="cancel"
                    size="small"
                    onClick={onExit}
                    type="light"
                  >
                    Close without saving
                  </Button>

                  <Button
                    style={{ margin: 0 }}
                    key="submit"
                    size="small"
                    type="danger"
                    htmlType="submit"
                    loading={loading}
                  >
                    Submit and reschedule shift
                  </Button>
                </footer>
              </Form>
            </div>
          )}
        </DataStateHandler>
      </Modal>
    </>
  );
}

export default ReasonsToRescheduleAppointmentModal;
