import React from "react";
import "antd/es/card/style";
import Skeleton from "antd/es/skeleton";
import "antd/es/skeleton/style";
import { Modal } from "../../atoms/modals/antd-modals";
import { Button } from "../../atoms/buttons/buttons";
import useGetAppointmentsDetails from "../../../utilities/hooks/useGetAppointmentDetails";
import styles from "./style.module.scss";
import { find, get } from "lodash";

function CancelAppointmentModal({ isOpen, onCancel, onExit, appointmentId, slotId = null, copyType = "" }) {
  const { data: selectedAppointment, isLoading, should_show_warning, able_to_adjust } = useGetAppointmentsDetails({
    appointmentId,
  });
  const mainObj = slotId ? find(selectedAppointment.cart_products, { cart_product_id: slotId }) : selectedAppointment;
  const fee = get(mainObj, "cancellation_fee", null);
  const feeCopy = get(mainObj, "cancel_fee_reason", "");

  return (
    <Modal
      wrapClassName={styles["modal-container"]}
      title={
        !isLoading &&
          slotId ? `Are you sure you want to remove this ${copyType}?`
          : `Are you sure you want to cancel?`
      }
      visible={isOpen}
      footer={null}
      onCancel={onExit}
      centered
    >
      {isLoading ? (
        <Skeleton active paragraph={{ rows: 9 }} />
      ) : (
        <div className="view-modal-body">
          <div className="view-modal-body-card" style={{ marginRight: 0 }}>
            {feeCopy ?
              <div style={{ marginBottom: "16px", fontSize: "16px", lineHeight: "24px" }}>
                <p>You're {feeCopy}</p>
                <span style={{ fontWeight: 500 }}>You will be charged {fee} {slotId ? `for removing this ${copyType}` : "for cancelling this appointment"}.</span> Are you sure you want {slotId ? `to remove this ${copyType}` : "to cancel this appointment"}?
              </div>
              : <div style={{ marginBottom: "16px", fontSize: "16px", lineHeight: "24px" }}>
                This action cannot be undone. Are you sure you want {slotId ? `to remove this ${copyType}` : "to cancel this appointment"}?
              </div>}
            <footer>
              <Button key={1} size="small" onClick={onExit} type="light">
                No, go back now
              </Button>

              <Button
                style={{ margin: 0 }}
                key={2}
                size="small"
                onClick={onCancel}
                type="danger"
              >
                Yes, {slotId ? `remove ${copyType}` : "cancel appointment"}
              </Button>
            </footer>
          </div>
        </div>
      )}
    </Modal>
  );
}

export default CancelAppointmentModal;
