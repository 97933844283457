import { useQuery } from "react-query";
import get from "lodash/get";
import useTryAutoLogin from "./useTryAutoLogin";
import appointment from "../api/appointment";

const useGetWaivableFees = (cart_id, client_fee = false) => {
  const { isLogin } = useTryAutoLogin();

  const { data, isError, error, isLoading, refetch } = useQuery(
    ["getWaivableFees", { cart_id, client_fee }],
    appointment.getWaivableList,
    {
      enabled: !!isLogin && !!cart_id,
      cacheTime: 2 * 60 * 1000,
      staleTime: 0,
    }
  );
  const cancellations = get(data, "data.cancellations");
  return { cancellations, isError, error, isLoading, refetch };
};

export default useGetWaivableFees;
